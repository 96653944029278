import React, { useState } from "react";

import ConnectForm from "../components/ConnectForm.js";
import { Modal } from "react-bootstrap";
import '../assets/connectcss/connect.css'
import vedio1 from "../assets/vedio/44666922f108a57649eb4eed8b544cd9.mp4";
import vedio2 from "../assets/vedio/af952516d2421c5d5208854f131f356e.mp4";
import vedio3 from "../assets/vedio/44666922f108a57649eb4eed8b544cd9.mp4";
import vedio4 from "../assets/vedio/3100edf7a90fcf3f0374972613c66981.mp4";
import vedio5 from "../assets/vedio/44666922f108a57649eb4eed8b544cd9.mp4";
import vedio6 from "../assets/vedio/44666922f108a57649eb4eed8b544cd9.mp4";
import vedio7 from "../assets/vedio/4d3f752a5e0180e6bb0c520d044a8ac4.mp4";
import vedio8 from "../assets/vedio/72245e06ec7fb18c1b1c5f586e075cc3.mp4";
import vedio9 from "../assets/vedio/af952516d2421c5d5208854f131f356e.mp4";
import vedio10 from "../assets/vedio/bd8851da8ec60d5c1788e397517bc9fe.mp4";
import vedio11 from "../assets/vedio/4d3f752a5e0180e6bb0c520d044a8ac4.mp4";
import vedio12 from "../assets/img/lp.png";
import desk from "../assets/img/desk.png";
import mobile from "../assets/img/mobile.png";
import vedio13 from "../assets/img/6ffd3bf347c72f9a97138144dea01de5.png";
import vedio14 from "../assets/img/ecfcee774f9a4f638d690eeb1bc590d4.jpg";
import vedio15 from "../assets/img/60c4565a180511a8a20de8c36e2732f3.png";
import vedio16 from "../assets/img/50cccb007771b8ece8e18c3350cdd011.png";
import spiral1 from "../assets/img/9d17385752258a3708f16d62c7b186b2.svg";
import spiral2 from "../assets/img/38f594c4d7078f36bd12c102f06af5e5.svg";
import logo1 from "../assets/img/b906848a291a5eeb56eedc608b45cbb6.png";
import logo2 from "../assets/img/6c8f65b71fa41846bc2b6b3b9bff62f7.png";
import logo3 from "../assets/img/a38dabbce50f5f989db856ab707178cc.jpg";
import arrow from "../assets/img/1cb0212b36f8e36e2dd2530a5ad66490.svg";
const Connect = () => {


  var video = document.querySelector(".myVideo");
  var playBtn = document.querySelector(".playbtn");
  var pauseBtn = document.querySelector(".pauseSvg1");
  const togglePlay = () => {
    if (video.paused || video.ended) {
      video.play();

      if (pauseBtn) pauseBtn.style.display = "block";
      if (playBtn) playBtn.style.display = "none";
    }
  };
  const togglePause = () => {
    if (video.play) {
      video.pause();

      if (pauseBtn) pauseBtn.style.display = "none";
      if (playBtn) playBtn.style.display = "block";
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const openModal = () => setShow(true);
  const divStyle = {
    color: "white",
    backgroundColor: "black",
  };

  return (
    <>
      <title>Stoyc</title>

      <div className="center-container text-white width:100vw;" style={divStyle}>
        <div className="container m-auto ">
          <div className="row pt-5 my-5" id="main">
            <div className=" col-md-3 pt-md-1">
              <div className="vedio-section">
                <svg
                  className="pauseSvg1"
                  onClick={togglePause}
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                >
                  <rect x={7} y={5} width={3} height={14} rx="1.5" fill="currentColor" />
                  <rect x={14} y={5} width={3} height={14} rx="1.5" fill="currentColor" />
                </svg>
                <svg
                  className="playbtn"
                  onClick={togglePlay}
                  width={24}
                  height={24}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ display: "block" }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.248 4.212l11.05 6.574c.694.412.91 1.29.483 1.961-.121.19-.287.35-.483.467l-11.05 6.574c-.694.413-1.602.204-2.03-.467A1.39 1.39 0 0 1 6 18.574V5.426C6 4.638 6.66 4 7.475 4c.273 0 .54.073.773.212z"
                    fill="currentColor"
                  />
                </svg>
                <svg id="uz6THAGVHf3FHrZX" viewBox="0 0 300.0 500.0">
                  <g id="whXDyGePuifGPLWO" style={{ transform: "scale(1, 1)" }}>
                    <foreignObject
                      id="bo68Ereii1kYt0KO"
                      style={{ width: "300px", height: "500px" }}
                    >
                      <div
                        id="yn56qgmdFd5VxErU"
                        style={{
                          clipPath:
                            'path("M220,500 L80,500 C35.79999924,500 0,464.20000076 0,420 L0,80 C0,35.79999924 35.79999924,0 80,0 L220,0 C264.20000076,0 300,35.79999924 300,80 L300,420 C300,464.20001221 264.20001221,500 220,500 Z")',
                        }}
                      >
                        <div
                          id="pumpkxmggqaiOoYX"
                          style={{
                            transform: "scale(1, 1)",
                            transformOrigin: "150px 250px",
                          }}
                        >
                          <video
                            className="myVideo"
                            controls
                            autoPlay
                            playsInline
                            preload="none"
                            muted
                            data-controls-width="100%"
                            data-controls-height="100%"
                            data-controls-top="0%"
                            data-controls-left="0%"
                            style={{
                              transform:
                                "translate(0px, -16.66666667px) rotate(0deg)",
                              width: "300px",
                              height: "533.33333333px",
                              display: "block",
                              opacity: 1.0,
                              objectFit: "fill",
                            }}
                          >
                            <source src={vedio1} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                    </foreignObject>
                    <path
                      id="PcZ8Y3Kf4xyApnrl"
                      d="M220,1.5 C263.29999924,1.5 298.5,36.70000076 298.5,80 L298.5,420 C298.5,463.29999924 263.29999924,498.5 220,498.5 L80,498.5 C36.70000076,498.5 1.5,463.29999924 1.5,420 L1.5,80 C1.5,36.70000076 36.70000076,1.5 80,1.5 L220,1.5 M220,0 L80,0 C35.79999924,0 0,35.79999924 0,80 L0,420 C0,464.20000076 35.79999924,500 80,500 L220,500 C264.20000076,500 300,464.20000076 300,420 L300,80 C300,35.79999924 264.20001221,0 220,0 Z"
                      style={{ fill: "#ffffff", opacity: 1.0 }}
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="col-md-9 mt-4 p-md-4">
              <div className="intro-container  text-white">
                <h1 className="headingupper  text-white">STOYC</h1>
                <p className="para  text-white"> 
                  <i>a boutique real estate marketing firm</i>
                </p>
                <div>
                  <button
                    id="btn1"
                    type="button"
                    onClick={openModal}
                    className="btn btn-primary"
                  >
                    Connect
                  </button>
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-sm-6">
                  <div className="right-bottom-block">
                    <div className="sec-vedio">
                      <div className="sound">
                        <input
                          className="videoSeekInput"
                          type="range"
                          min={0}
                          max="59.6"
                          step="any"
                          defaultValue={0}
                        />
                        <svg
                          className="soundOnSvg"
                          width={24}
                          height={24}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ display: "block" }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.5 5.102L8.778 8.35a2.5 2.5 0 0 1-1.643.616H4.5v6.065h2.632a2.5 2.5 0 0 1 1.644.617l3.724 3.25V5.102zM14 19.998c0 .858-1.01 1.318-1.658.753L7.79 16.778a1 1 0 0 0-.658-.247H4a1 1 0 0 1-1-1V8.466a1 1 0 0 1 1-1h3.135a1 1 0 0 0 .657-.246l4.55-3.971C12.99 2.684 14 3.143 14 4.002v15.996zM15.25 7a.75.75 0 0 1 .75-.75 5.75 5.75 0 0 1 0 11.5.75.75 0 0 1 0-1.5 4.25 4.25 0 0 0 0-8.5.75.75 0 0 1-.75-.75zM16 9.25a.75.75 0 0 0 0 1.5 1.25 1.25 0 1 1 0 2.5.75.75 0 0 0 0 1.5 2.75 2.75 0 1 0 0-5.5z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <svg
                        className="secvedio"
                        onClick={togglePause}
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                      >
                        <rect
                          x={7}
                          y={5}
                          width={3}
                          height={14}
                          rx="1.5"
                          fill="currentColor"
                        />
                        <rect
                          x={14}
                          y={5}
                          width={3}
                          height={14}
                          rx="1.5"
                          fill="currentColor"
                        />
                      </svg>
                      <svg
                        className="secvedioplay"
                        onClick={togglePlay}
                        width={24}
                        height={24}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ display: "block" }}
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.248 4.212l11.05 6.574c.694.412.91 1.29.483 1.961-.121.19-.287.35-.483.467l-11.05 6.574c-.694.413-1.602.204-2.03-.467A1.39 1.39 0 0 1 6 18.574V5.426C6 4.638 6.66 4 7.475 4c.273 0 .54.073.773.212z"
                          fill="currentColor"
                        />
                      </svg>
                      <svg
                        id="QBg9H32b4mv5WbIH"
                        viewBox="0 0 500.0 250.0"
                        style={{ overflow: "hidden" }}
                      >
                        <g
                          id=" xs4pIKUu3TiQcXVL"
                          style={{ transform: "scale(1, 1)" }}
                        >
                          <foreignObject
                            id="KjuHwy7ri8uSIKuO"
                            style={{ width: "500px", height: "250px" }}
                          >
                            <div
                              id="D6sm34hucFbNadyk"
                              style={{
                                clipPath:
                                  'path("M0,180 L0,70 C0,31.29999924 31.29999924,0 70,0 L430,0 C468.70000076,0 500,31.29999924 500,70 L500,180 C500,218.70000076 468.70000076,250 430,250 L70,250 C31.29999924,250 0,218.69999695 0,180 Z")',
                              }}
                            >
                              <div
                                id="CAxKG4sPnI0JrLJS"
                                style={{
                                  transform: "scale(1, 1)",
                                  transformOrigin: "250px 125px",
                                }}
                              >
                                <video
                                  className="myVideo"
                                  controls
                                  autoPlay
                                  playsInline
                                  preload="none"
                                  muted
                                  data-controls-width="100%"
                                  data-controls-height="100%"
                                  data-controls-top="0%"
                                  data-controls-left="0%"
                                  style={{
                                    clipPath: 'path("M0,180 L0,70 C0,31.29999924 31.29999924,0 70,0 L430,0 C468.70000076,0 500,31.29999924 500,70 L500,180 C500,218.70000076 468.70000076,250 430,250 L70,250 C31.29999924,250 0,218.69999695 0,180 Z")',
                                  }}
                                >
                                  <source src={vedio2} type="video/mp4" />
                                  Your browser does not support the video
                                  element. Kindly update it to latest version.
                                </video>
                              </div>
                            </div>
                          </foreignObject>
                          <path
                            id="oSPt48zVaM1RgryP"
                            d="M430,1.5 C448.29999924,1.5 465.5,8.5999999 478.5,21.5 C491.39999962,34.39999962 498.5,51.60000038 498.5,70 L498.5,180 C498.5,198.29999924 491.4000001,215.5 478.5,228.5 C465.60000038,241.39999962 448.39999962,248.5 430,248.5 L70,248.5 C51.70000076,248.5 34.5,241.4000001 21.5,228.5 C8.60000038,215.60000038 1.5,198.39999962 1.5,180 L1.5,70 C1.5,51.70000076 8.5999999,34.5 21.5,21.5 C34.5,8.60000038 51.70000076,1.5 70,1.5 L430,1.5 M430,0 L70,0 C31.29999924,0 0,31.29999924 0,70 L0,180 C0,218.70000076 31.29999924,250 70,250 L430,250 C468.70000076,250 500,218.70000076 500,180 L500,70 C500,31.29999924 468.70001221,0 430,0 Z"
                            style={{ fill: "#ffffff", opacity: 1.0 }}
                          />
                        </g>
                      </svg>
                    </div>
                    {/* spiral */}
                  </div>
                </div>
                <div className="col-6">
                  <div className="spiral-svgimg">
                    <img
                      src={spiral1}
                      alt="Ultra Thin Lined Spiral"
                      loading="lazy"
                    
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="phone-videos">
            <div className="heading-wrap">
              <h2 className="heading-wwd  fw-normal text-white">What We Do</h2>
              <div className="spiral1">
                <img src={spiral2} id="img" alt="spiral" />
              </div>
            </div>
            <div   id="phones">
           
              <div className="row">
                <div className="col-sm-3 p-4">
                  <div className="mobile-video-wrap">

                 
                  <div className="soundButton">
                    <input
                      className="videoSeekInput"
                      type="range"
                      min={0}
                      max="59.6"
                      step="any"
                      defaultValue={0}
                    />
                    <svg
                      className="soundOnSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "none" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 5.102L8.778 8.35a2.5 2.5 0 0 1-1.643.616H4.5v6.065h2.632a2.5 2.5 0 0 1 1.644.617l3.724 3.25V5.102zM14 19.998c0 .858-1.01 1.318-1.658.753L7.79 16.778a1 1 0 0 0-.658-.247H4a1 1 0 0 1-1-1V8.466a1 1 0 0 1 1-1h3.135a1 1 0 0 0 .657-.246l4.55-3.971C12.99 2.684 14 3.143 14 4.002v15.996zM15.25 7a.75.75 0 0 1 .75-.75 5.75 5.75 0 0 1 0 11.5.75.75 0 0 1 0-1.5 4.25 4.25 0 0 0 0-8.5.75.75 0 0 1-.75-.75zM16 9.25a.75.75 0 0 0 0 1.5 1.25 1.25 0 1 1 0 2.5.75.75 0 0 0 0 1.5 2.75 2.75 0 1 0 0-5.5z"
                        fill="currentColor"
                      />
                    </svg>
                    <svg
                      className="soundMutedSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "block" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5 5.08l-3.742 3.171a2.5 2.5 0 01-.243.182L15.5 12.4V5.08zm1.5 8.814V4.002a1 1 0 00-1.646-.763l-4.566 3.868a1 1 0 01-.398.206L6.53 3.468a.75.75 0 10-1.06 1.063l14 13.94a.75.75 0 101.06-1.063L17 13.894zM6.293 7.637l1.208 1.207H7.5v5.8h2.642a2.5 2.5 0 011.616.593l3.742 3.17v-1.564l1.5 1.5v1.143a1 1 0 01-1.646.763l-4.566-3.868a1 1 0 00-.646-.237H7a1 1 0 01-1-1v-6.8c0-.276.112-.526.293-.707z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <svg
                    className="pauseSvg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  >
                    <rect
                      x={7}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                    <rect
                      x={14}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                  </svg>
                  <svg
                    className="playbtn"
                    onClick={togglePlay}
                    width={24}
                    height={24}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "block" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.248 4.212l11.05 6.574c.694.412.91 1.29.483 1.961-.121.19-.287.35-.483.467l-11.05 6.574c-.694.413-1.602.204-2.03-.467A1.39 1.39 0 0 1 6 18.574V5.426C6 4.638 6.66 4 7.475 4c.273 0 .54.073.773.212z"
                      fill="currentColor"
                    />
                  </svg>
                  <svg id="ZDipjYY5Io7HK7ka" viewBox="0 0 206.3 408.2">
                    <g
                      id="iW53AWUqGRXrddSj"
                      style={{ transform: "scale(1, 1)" }}
                    >
                      <path
                        id="l9n5lZ6G2hMsIERi"
                        d="M174.19999695,2 L32.09999084,2 C16.69999123,2 4.19999123,14.5 4.19999123,29.89999962 L4.19999123,378.19998741 C4.19999123,393.59998703 16.69999123,406.09998703 32.09999084,406.09998703 L174.19999695,406.09998703 C189.59999657,406.09998703 202.09999657,393.59998703 202.09999657,378.19998741 L202.09999657,29.89999962 C202.09999657,14.5 189.59999657,2 174.19999695,2 Z M126.89999771,14.80000019 C128.29999769,14.80000019 129.39999771,15.90000021 129.39999771,17.30000019 C129.39999771,18.70000017 128.29999769,19.80000019 126.89999771,19.80000019 C125.49999774,19.80000019 124.39999771,18.70000017 124.39999771,17.30000019 C124.39999771,15.90000021 125.59999776,14.80000019 126.89999771,14.80000019 Z M89.99999619,15.6000002 L113.99999619,15.6000002 C114.89999616,15.6000002 115.69999623,16.30000019 115.69999623,17.30000025 C115.69999623,18.30000031 114.89999622,19.0000003 113.99999619,19.0000003 L89.99999619,19.0000003 C89.09999621,19.0000003 88.29999614,18.30000031 88.29999614,17.30000025 C88.29999614,16.30000019 89.09999615,15.6000002 89.99999619,15.6000002 Z M191.89999771,379.80001241 C191.89999771,388.90001279 184.49999762,396.30001241 175.39999771,396.30001241 L31.09999466,396.30001241 C21.99999428,396.30001241 14.59999466,388.90001231 14.59999466,379.80001241 L14.59999466,28.80001241 C14.59999466,19.70001203 21.99999475,12.30001241 31.09999466,12.30001241 L52.59999466,12.30001241 L52.59999466,16.80001241 C52.59999466,21.90001231 56.79999447,26.1000126 61.89999485,26.1000126 L144.5999918,26.1000126 C149.6999917,26.1000126 153.89999199,21.90001279 153.89999199,16.80001241 L153.89999199,12.30001241 L175.29999161,12.30001241 C184.39999199,12.30001241 191.79999161,19.70001251 191.79999161,28.80001241 L191.79999161,379.80001241 Z"
                        style={{ fill: "#000000", opacity: 1.0 }}
                      />
                      <foreignObject
                        id="Pc6e4uSnn76we6ea"
                        style={{ width: "206.3px", height: "408.2px" }}
                      >
                        <div
                          id="MkIFM5vtu7uAnxiZ"
                          style={{
                            clipPath:
                              'path("M175.30000305,12.30000019 L153.90000343,12.30000019 L153.90000343,16.80000019 C153.90000343,21.9000001 149.70000362,26.10000038 144.60000324,26.10000038 L62.00000477,26.10000038 C56.90000486,26.10000038 52.70000458,21.90000057 52.70000458,16.80000019 L52.70000458,12.30000019 L31.1000042,12.30000019 C22.00000381,12.30000019 14.6000042,19.70000029 14.6000042,28.80000019 L14.6000042,379.80000019 C14.6000042,388.90000057 22.00000429,396.30000019 31.1000042,396.30000019 L175.30000114,396.30000019 C184.40000153,396.30000019 191.80000114,388.9000001 191.80000114,379.80000019 L191.80000114,28.80000019 C191.90000115,19.69999981 184.50000095,12.30000019 175.30000114,12.30000019 Z")',
                          }}
                        >
                          <div
                            id="i4KmmULDmpasgBey"
                            style={{
                              transform: "scale(1, 1)",
                              transformOrigin: "103px 204.5px",
                            }}
                          >
                            <video
                              className="myVideo"
                              controls
                              autoPlay
                              playsInline
                              preload="none"
                              muted
                              data-controls-width="100%"
                              data-controls-height="100%"
                              data-controls-top="0%"
                              data-controls-left="0%"
                            >
                              <source src={vedio3} type="video/mp4" />
                              Your browser does not support the video element.
                              Kindly update it to latest version.
                            </video>
                          </div>
                        </div>
                      </foreignObject>
                      <path
                        id="TNIQYNLWtoEOZu6x"
                        d="M114,15.60000038 L90,15.60000038 C89.10000002,15.60000038 88.29999995,16.30000037 88.29999995,17.30000043 C88.29999995,18.30000049 89.09999996,19.00000048 90,19.00000048 L114,19.00000048 C114.89999998,19.00000048 115.70000005,18.30000049 115.70000005,17.30000043 C115.70000005,16.30000037 114.90000004,15.60000038 114,15.60000038 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="t5mdwujubx3qVrkk"
                        d="M126.90000153,14.80000019 C126.00646995,14.79944832 125.18057651,15.2758248 124.73365133,16.049554 C124.28672615,16.8232832 124.28672615,17.77671623 124.73365133,18.55044543 C125.18057651,19.32417462 126.00646995,19.80055111 126.90000153,19.79999924 C127.79353311,19.80055111 128.61942655,19.32417462 129.06635172,18.55044543 C129.5132769,17.77671623 129.5132769,16.8232832 129.06635172,16.049554 C128.61942655,15.2758248 127.79353311,14.79944832 126.90000153,14.80000019 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="ukTKr9pKfwF2Ydfy"
                        d="M0,56.09999847 L0,68.59999847 C0,69.79999852 1,70.79999852 2.20000005,70.79999852 L2.20000005,53.99999928 C1,53.99999928 0,54.89999926 0,56.09999919 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="lED1TSZF5niX4ra6"
                        d="M0,85.40000153 L0,111.40000153 C0,112.60000157 1,113.60000157 2.20000005,113.60000157 L2.20000005,83.30000234 C1,83.30000234 0,84.20000231 0,85.40000224 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hQoHW4klnEWgJ7ZV"
                        d="M0,122.40000153 L0,148.40000153 C0,149.60000157 1,150.60000157 2.20000005,150.60000157 L2.20000005,120.20000196 C1,120.20000196 0,121.20000196 0,122.400002 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="Gt7QuQ4BNnpTM3tk"
                        d="M204.1000061,93.30000305 L204.1000061,142.00000381 C205.30000615,142.00000381 206.30000615,141.00000381 206.30000615,139.80000377 L206.30000615,95.50000453 C206.30000615,94.30000448 205.30000615,93.30000448 204.1000061,93.30000448 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hPuclasitIuFFt7k"
                        d="M204.1000061,93.30000305 L204.1000061,29.90000153 C204.1000061,27.80000162 203.9000061,25.80000162 203.50000608,23.90000153 C203.30000608,22.90000153 203.10000607,22.00000155 202.80000609,21.00000143 C202.40000609,19.60000145 201.80000609,18.20000148 201.20000607,16.90000153 C200.80000606,16.00000155 200.30000609,15.20000148 199.80000609,14.40000153 C199.30000609,13.60000157 198.80000609,12.8000015 198.20000607,12.00000143 C197.30000609,10.80000138 196.40000612,9.80000138 195.40000612,8.70000148 C194.70000613,8.00000149 194.00000614,7.40000153 193.30000621,6.8000015 C188.1000064,2.50000131 181.50000602,0.00000131 174.30000621,0.00000131 L32.10000926,0.00000131 C24.90000945,0.00000131 18.20000964,2.60000122 13.10000926,6.8000015 C12.40000927,7.40000153 11.70000929,8.10000145 11.00000936,8.70000148 C10.00000936,9.70000148 9.00000936,10.80000138 8.20000941,12.00000143 C7.60000938,12.80000144 7.10000938,13.60000145 6.60000938,14.40000153 C6.10000938,15.2000016 5.60000938,16.10000157 5.20000941,16.90000153 C4.50000942,18.3000015 4.00000936,19.60000157 3.50000936,21.00000143 C3.20000935,22.00000143 3.00000936,22.90000141 2.80000937,23.90000153 C2.40000936,25.8000015 2.20000935,27.90000153 2.20000935,29.90000153 L2.20000935,378.19998932 C2.20000935,394.69998932 15.60000896,408.09998894 32.10000896,408.09998894 L174.20001507,408.09998894 C190.70001507,408.09998894 204.10001469,394.69998932 204.10001469,378.19998932 L204.10001469,93.29999542 Z M202.10001469,93.29999542 L202.10001469,378.29999542 C202.10001469,393.69999504 189.60001469,406.19999504 174.20001507,406.19999504 L32.10000896,406.19999504 C16.70000935,406.19999504 4.20000935,393.69999504 4.20000935,378.29999542 L4.20000935,30.00000763 C4.20000935,14.50000763 16.70000935,2.00000763 32.10000896,2.00000763 L174.20001507,2.00000763 C189.60001469,2.00000763 202.10001469,14.50000763 202.10001469,29.90000725 L202.10001469,93.30000877 Z"
                        style={{ fill: "#fce9d8", opacity: 1.0 }}
                      />
                    </g>
                  </svg>
                  </div>
                  </div>
                <div className="col-sm-3 p-4">
                  <div className="mobile-video-wrap">
                  <div className="soundButton">
                    <input
                      className="videoSeekInput"
                      type="range"
                      min={0}
                      max="59.6"
                      step="any"
                      defaultValue={0}
                    />
                    <svg
                      className="soundOnSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "none" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 5.102L8.778 8.35a2.5 2.5 0 0 1-1.643.616H4.5v6.065h2.632a2.5 2.5 0 0 1 1.644.617l3.724 3.25V5.102zM14 19.998c0 .858-1.01 1.318-1.658.753L7.79 16.778a1 1 0 0 0-.658-.247H4a1 1 0 0 1-1-1V8.466a1 1 0 0 1 1-1h3.135a1 1 0 0 0 .657-.246l4.55-3.971C12.99 2.684 14 3.143 14 4.002v15.996zM15.25 7a.75.75 0 0 1 .75-.75 5.75 5.75 0 0 1 0 11.5.75.75 0 0 1 0-1.5 4.25 4.25 0 0 0 0-8.5.75.75 0 0 1-.75-.75zM16 9.25a.75.75 0 0 0 0 1.5 1.25 1.25 0 1 1 0 2.5.75.75 0 0 0 0 1.5 2.75 2.75 0 1 0 0-5.5z"
                        fill="currentColor"
                      />
                    </svg>
                    <svg
                      className="soundMutedSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "block" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5 5.08l-3.742 3.171a2.5 2.5 0 01-.243.182L15.5 12.4V5.08zm1.5 8.814V4.002a1 1 0 00-1.646-.763l-4.566 3.868a1 1 0 01-.398.206L6.53 3.468a.75.75 0 10-1.06 1.063l14 13.94a.75.75 0 101.06-1.063L17 13.894zM6.293 7.637l1.208 1.207H7.5v5.8h2.642a2.5 2.5 0 011.616.593l3.742 3.17v-1.564l1.5 1.5v1.143a1 1 0 01-1.646.763l-4.566-3.868a1 1 0 00-.646-.237H7a1 1 0 01-1-1v-6.8c0-.276.112-.526.293-.707z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <svg
                    className="pauseSvg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  >
                    <rect
                      x={7}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                    <rect
                      x={14}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                  </svg>
                  <svg
                    className="playbtn"
                    width={24}
                    height={24}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "block" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.248 4.212l11.05 6.574c.694.412.91 1.29.483 1.961-.121.19-.287.35-.483.467l-11.05 6.574c-.694.413-1.602.204-2.03-.467A1.39 1.39 0 0 1 6 18.574V5.426C6 4.638 6.66 4 7.475 4c.273 0 .54.073.773.212z"
                      fill="currentColor"
                    />
                  </svg>
                  <svg id="ZDipjYY5Io7HK7ka" viewBox="0 0 206.3 408.2">
                    <g
                      id="iW53AWUqGRXrddSj"
                      style={{ transform: "scale(1, 1)" }}
                    >
                      <path
                        id="l9n5lZ6G2hMsIERi"
                        d="M174.19999695,2 L32.09999084,2 C16.69999123,2 4.19999123,14.5 4.19999123,29.89999962 L4.19999123,378.19998741 C4.19999123,393.59998703 16.69999123,406.09998703 32.09999084,406.09998703 L174.19999695,406.09998703 C189.59999657,406.09998703 202.09999657,393.59998703 202.09999657,378.19998741 L202.09999657,29.89999962 C202.09999657,14.5 189.59999657,2 174.19999695,2 Z M126.89999771,14.80000019 C128.29999769,14.80000019 129.39999771,15.90000021 129.39999771,17.30000019 C129.39999771,18.70000017 128.29999769,19.80000019 126.89999771,19.80000019 C125.49999774,19.80000019 124.39999771,18.70000017 124.39999771,17.30000019 C124.39999771,15.90000021 125.59999776,14.80000019 126.89999771,14.80000019 Z M89.99999619,15.6000002 L113.99999619,15.6000002 C114.89999616,15.6000002 115.69999623,16.30000019 115.69999623,17.30000025 C115.69999623,18.30000031 114.89999622,19.0000003 113.99999619,19.0000003 L89.99999619,19.0000003 C89.09999621,19.0000003 88.29999614,18.30000031 88.29999614,17.30000025 C88.29999614,16.30000019 89.09999615,15.6000002 89.99999619,15.6000002 Z M191.89999771,379.80001241 C191.89999771,388.90001279 184.49999762,396.30001241 175.39999771,396.30001241 L31.09999466,396.30001241 C21.99999428,396.30001241 14.59999466,388.90001231 14.59999466,379.80001241 L14.59999466,28.80001241 C14.59999466,19.70001203 21.99999475,12.30001241 31.09999466,12.30001241 L52.59999466,12.30001241 L52.59999466,16.80001241 C52.59999466,21.90001231 56.79999447,26.1000126 61.89999485,26.1000126 L144.5999918,26.1000126 C149.6999917,26.1000126 153.89999199,21.90001279 153.89999199,16.80001241 L153.89999199,12.30001241 L175.29999161,12.30001241 C184.39999199,12.30001241 191.79999161,19.70001251 191.79999161,28.80001241 L191.79999161,379.80001241 Z"
                        style={{ fill: "#000000", opacity: 1.0 }}
                      />
                      <foreignObject
                        id="Pc6e4uSnn76we6ea"
                        style={{ width: "206.3px", height: "408.2px" }}
                      >
                        <div
                          id="MkIFM5vtu7uAnxiZ"
                          style={{
                            clipPath:
                              'path("M175.30000305,12.30000019 L153.90000343,12.30000019 L153.90000343,16.80000019 C153.90000343,21.9000001 149.70000362,26.10000038 144.60000324,26.10000038 L62.00000477,26.10000038 C56.90000486,26.10000038 52.70000458,21.90000057 52.70000458,16.80000019 L52.70000458,12.30000019 L31.1000042,12.30000019 C22.00000381,12.30000019 14.6000042,19.70000029 14.6000042,28.80000019 L14.6000042,379.80000019 C14.6000042,388.90000057 22.00000429,396.30000019 31.1000042,396.30000019 L175.30000114,396.30000019 C184.40000153,396.30000019 191.80000114,388.9000001 191.80000114,379.80000019 L191.80000114,28.80000019 C191.90000115,19.69999981 184.50000095,12.30000019 175.30000114,12.30000019 Z")',
                          }}
                        >
                          <div
                            id="i4KmmULDmpasgBey"
                            style={{
                              transform: "scale(1, 1)",
                              transformOrigin: "103px 204.5px",
                            }}
                          >
                            <video
                              className="myVideo"
                              controls
                              autoPlay
                              playsInline
                              preload="none"
                              muted
                              data-controls-width="100%"
                              data-controls-height="100%"
                              data-controls-top="0%"
                              data-controls-left="0%"
                            >
                              <source src={vedio4} type="video/mp4" />
                              Your browser does not support the video element.
                              Kindly update it to latest version.
                            </video>
                          </div>
                        </div>
                      </foreignObject>
                      <path
                        id="TNIQYNLWtoEOZu6x"
                        d="M114,15.60000038 L90,15.60000038 C89.10000002,15.60000038 88.29999995,16.30000037 88.29999995,17.30000043 C88.29999995,18.30000049 89.09999996,19.00000048 90,19.00000048 L114,19.00000048 C114.89999998,19.00000048 115.70000005,18.30000049 115.70000005,17.30000043 C115.70000005,16.30000037 114.90000004,15.60000038 114,15.60000038 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="t5mdwujubx3qVrkk"
                        d="M126.90000153,14.80000019 C126.00646995,14.79944832 125.18057651,15.2758248 124.73365133,16.049554 C124.28672615,16.8232832 124.28672615,17.77671623 124.73365133,18.55044543 C125.18057651,19.32417462 126.00646995,19.80055111 126.90000153,19.79999924 C127.79353311,19.80055111 128.61942655,19.32417462 129.06635172,18.55044543 C129.5132769,17.77671623 129.5132769,16.8232832 129.06635172,16.049554 C128.61942655,15.2758248 127.79353311,14.79944832 126.90000153,14.80000019 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="ukTKr9pKfwF2Ydfy"
                        d="M0,56.09999847 L0,68.59999847 C0,69.79999852 1,70.79999852 2.20000005,70.79999852 L2.20000005,53.99999928 C1,53.99999928 0,54.89999926 0,56.09999919 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="lED1TSZF5niX4ra6"
                        d="M0,85.40000153 L0,111.40000153 C0,112.60000157 1,113.60000157 2.20000005,113.60000157 L2.20000005,83.30000234 C1,83.30000234 0,84.20000231 0,85.40000224 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hQoHW4klnEWgJ7ZV"
                        d="M0,122.40000153 L0,148.40000153 C0,149.60000157 1,150.60000157 2.20000005,150.60000157 L2.20000005,120.20000196 C1,120.20000196 0,121.20000196 0,122.400002 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="Gt7QuQ4BNnpTM3tk"
                        d="M204.1000061,93.30000305 L204.1000061,142.00000381 C205.30000615,142.00000381 206.30000615,141.00000381 206.30000615,139.80000377 L206.30000615,95.50000453 C206.30000615,94.30000448 205.30000615,93.30000448 204.1000061,93.30000448 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hPuclasitIuFFt7k"
                        d="M204.1000061,93.30000305 L204.1000061,29.90000153 C204.1000061,27.80000162 203.9000061,25.80000162 203.50000608,23.90000153 C203.30000608,22.90000153 203.10000607,22.00000155 202.80000609,21.00000143 C202.40000609,19.60000145 201.80000609,18.20000148 201.20000607,16.90000153 C200.80000606,16.00000155 200.30000609,15.20000148 199.80000609,14.40000153 C199.30000609,13.60000157 198.80000609,12.8000015 198.20000607,12.00000143 C197.30000609,10.80000138 196.40000612,9.80000138 195.40000612,8.70000148 C194.70000613,8.00000149 194.00000614,7.40000153 193.30000621,6.8000015 C188.1000064,2.50000131 181.50000602,0.00000131 174.30000621,0.00000131 L32.10000926,0.00000131 C24.90000945,0.00000131 18.20000964,2.60000122 13.10000926,6.8000015 C12.40000927,7.40000153 11.70000929,8.10000145 11.00000936,8.70000148 C10.00000936,9.70000148 9.00000936,10.80000138 8.20000941,12.00000143 C7.60000938,12.80000144 7.10000938,13.60000145 6.60000938,14.40000153 C6.10000938,15.2000016 5.60000938,16.10000157 5.20000941,16.90000153 C4.50000942,18.3000015 4.00000936,19.60000157 3.50000936,21.00000143 C3.20000935,22.00000143 3.00000936,22.90000141 2.80000937,23.90000153 C2.40000936,25.8000015 2.20000935,27.90000153 2.20000935,29.90000153 L2.20000935,378.19998932 C2.20000935,394.69998932 15.60000896,408.09998894 32.10000896,408.09998894 L174.20001507,408.09998894 C190.70001507,408.09998894 204.10001469,394.69998932 204.10001469,378.19998932 L204.10001469,93.29999542 Z M202.10001469,93.29999542 L202.10001469,378.29999542 C202.10001469,393.69999504 189.60001469,406.19999504 174.20001507,406.19999504 L32.10000896,406.19999504 C16.70000935,406.19999504 4.20000935,393.69999504 4.20000935,378.29999542 L4.20000935,30.00000763 C4.20000935,14.50000763 16.70000935,2.00000763 32.10000896,2.00000763 L174.20001507,2.00000763 C189.60001469,2.00000763 202.10001469,14.50000763 202.10001469,29.90000725 L202.10001469,93.30000877 Z"
                        style={{ fill: "#fce9d8", opacity: 1.0 }}
                      />
                    </g>
                  </svg>
                </div>
                </div>
                <div className="col-sm-3 p-4">
                  <div className="mobile-video-wrap">
                  <div className="soundButton">
                    <input
                      className="videoSeekInput"
                      type="range"
                      min={0}
                      max="59.6"
                      step="any"
                      defaultValue={0}
                    />
                    <svg
                      className="soundOnSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "none" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 5.102L8.778 8.35a2.5 2.5 0 0 1-1.643.616H4.5v6.065h2.632a2.5 2.5 0 0 1 1.644.617l3.724 3.25V5.102zM14 19.998c0 .858-1.01 1.318-1.658.753L7.79 16.778a1 1 0 0 0-.658-.247H4a1 1 0 0 1-1-1V8.466a1 1 0 0 1 1-1h3.135a1 1 0 0 0 .657-.246l4.55-3.971C12.99 2.684 14 3.143 14 4.002v15.996zM15.25 7a.75.75 0 0 1 .75-.75 5.75 5.75 0 0 1 0 11.5.75.75 0 0 1 0-1.5 4.25 4.25 0 0 0 0-8.5.75.75 0 0 1-.75-.75zM16 9.25a.75.75 0 0 0 0 1.5 1.25 1.25 0 1 1 0 2.5.75.75 0 0 0 0 1.5 2.75 2.75 0 1 0 0-5.5z"
                        fill="currentColor"
                      />
                    </svg>
                    <svg
                      className="soundMutedSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "block" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5 5.08l-3.742 3.171a2.5 2.5 0 01-.243.182L15.5 12.4V5.08zm1.5 8.814V4.002a1 1 0 00-1.646-.763l-4.566 3.868a1 1 0 01-.398.206L6.53 3.468a.75.75 0 10-1.06 1.063l14 13.94a.75.75 0 101.06-1.063L17 13.894zM6.293 7.637l1.208 1.207H7.5v5.8h2.642a2.5 2.5 0 011.616.593l3.742 3.17v-1.564l1.5 1.5v1.143a1 1 0 01-1.646.763l-4.566-3.868a1 1 0 00-.646-.237H7a1 1 0 01-1-1v-6.8c0-.276.112-.526.293-.707z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <svg
                    className="pauseSvg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  >
                    <rect
                      x={7}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                    <rect
                      x={14}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                  </svg>
                  <svg
                    className="playbtn"
                    onClick={togglePlay}
                    width={24}
                    height={24}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "block" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.248 4.212l11.05 6.574c.694.412.91 1.29.483 1.961-.121.19-.287.35-.483.467l-11.05 6.574c-.694.413-1.602.204-2.03-.467A1.39 1.39 0 0 1 6 18.574V5.426C6 4.638 6.66 4 7.475 4c.273 0 .54.073.773.212z"
                      fill="currentColor"
                    />
                  </svg>
                  <svg id="ZDipjYY5Io7HK7ka" viewBox="0 0 206.3 408.2">
                    <g
                      id="iW53AWUqGRXrddSj"
                      style={{ transform: "scale(1, 1)" }}
                    >
                      <path
                        id="l9n5lZ6G2hMsIERi"
                        d="M174.19999695,2 L32.09999084,2 C16.69999123,2 4.19999123,14.5 4.19999123,29.89999962 L4.19999123,378.19998741 C4.19999123,393.59998703 16.69999123,406.09998703 32.09999084,406.09998703 L174.19999695,406.09998703 C189.59999657,406.09998703 202.09999657,393.59998703 202.09999657,378.19998741 L202.09999657,29.89999962 C202.09999657,14.5 189.59999657,2 174.19999695,2 Z M126.89999771,14.80000019 C128.29999769,14.80000019 129.39999771,15.90000021 129.39999771,17.30000019 C129.39999771,18.70000017 128.29999769,19.80000019 126.89999771,19.80000019 C125.49999774,19.80000019 124.39999771,18.70000017 124.39999771,17.30000019 C124.39999771,15.90000021 125.59999776,14.80000019 126.89999771,14.80000019 Z M89.99999619,15.6000002 L113.99999619,15.6000002 C114.89999616,15.6000002 115.69999623,16.30000019 115.69999623,17.30000025 C115.69999623,18.30000031 114.89999622,19.0000003 113.99999619,19.0000003 L89.99999619,19.0000003 C89.09999621,19.0000003 88.29999614,18.30000031 88.29999614,17.30000025 C88.29999614,16.30000019 89.09999615,15.6000002 89.99999619,15.6000002 Z M191.89999771,379.80001241 C191.89999771,388.90001279 184.49999762,396.30001241 175.39999771,396.30001241 L31.09999466,396.30001241 C21.99999428,396.30001241 14.59999466,388.90001231 14.59999466,379.80001241 L14.59999466,28.80001241 C14.59999466,19.70001203 21.99999475,12.30001241 31.09999466,12.30001241 L52.59999466,12.30001241 L52.59999466,16.80001241 C52.59999466,21.90001231 56.79999447,26.1000126 61.89999485,26.1000126 L144.5999918,26.1000126 C149.6999917,26.1000126 153.89999199,21.90001279 153.89999199,16.80001241 L153.89999199,12.30001241 L175.29999161,12.30001241 C184.39999199,12.30001241 191.79999161,19.70001251 191.79999161,28.80001241 L191.79999161,379.80001241 Z"
                        style={{ fill: "#000000", opacity: 1.0 }}
                      />
                      <foreignObject
                        id="Pc6e4uSnn76we6ea"
                        style={{ width: "206.3px", height: "408.2px" }}
                      >
                        <div
                          id="MkIFM5vtu7uAnxiZ"
                          style={{
                            clipPath:
                              'path("M175.30000305,12.30000019 L153.90000343,12.30000019 L153.90000343,16.80000019 C153.90000343,21.9000001 149.70000362,26.10000038 144.60000324,26.10000038 L62.00000477,26.10000038 C56.90000486,26.10000038 52.70000458,21.90000057 52.70000458,16.80000019 L52.70000458,12.30000019 L31.1000042,12.30000019 C22.00000381,12.30000019 14.6000042,19.70000029 14.6000042,28.80000019 L14.6000042,379.80000019 C14.6000042,388.90000057 22.00000429,396.30000019 31.1000042,396.30000019 L175.30000114,396.30000019 C184.40000153,396.30000019 191.80000114,388.9000001 191.80000114,379.80000019 L191.80000114,28.80000019 C191.90000115,19.69999981 184.50000095,12.30000019 175.30000114,12.30000019 Z")',
                          }}
                        >
                          <div
                            id="i4KmmULDmpasgBey"
                            style={{
                              transform: "scale(1, 1)",
                              transformOrigin: "103px 204.5px",
                            }}
                          >
                            <video
                              className="myVideo"
                              controls
                              autoPlay
                              playsInline
                              preload="none"
                              muted
                              data-controls-width="100%"
                              data-controls-height="100%"
                              data-controls-top="0%"
                              data-controls-left="0%"
                            >
                              <source src={vedio5} type="video/mp4" />
                              Your browser does not support the video element.
                              Kindly update it to latest version.
                            </video>
                          </div>
                        </div>
                      </foreignObject>
                      <path
                        id="TNIQYNLWtoEOZu6x"
                        d="M114,15.60000038 L90,15.60000038 C89.10000002,15.60000038 88.29999995,16.30000037 88.29999995,17.30000043 C88.29999995,18.30000049 89.09999996,19.00000048 90,19.00000048 L114,19.00000048 C114.89999998,19.00000048 115.70000005,18.30000049 115.70000005,17.30000043 C115.70000005,16.30000037 114.90000004,15.60000038 114,15.60000038 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="t5mdwujubx3qVrkk"
                        d="M126.90000153,14.80000019 C126.00646995,14.79944832 125.18057651,15.2758248 124.73365133,16.049554 C124.28672615,16.8232832 124.28672615,17.77671623 124.73365133,18.55044543 C125.18057651,19.32417462 126.00646995,19.80055111 126.90000153,19.79999924 C127.79353311,19.80055111 128.61942655,19.32417462 129.06635172,18.55044543 C129.5132769,17.77671623 129.5132769,16.8232832 129.06635172,16.049554 C128.61942655,15.2758248 127.79353311,14.79944832 126.90000153,14.80000019 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="ukTKr9pKfwF2Ydfy"
                        d="M0,56.09999847 L0,68.59999847 C0,69.79999852 1,70.79999852 2.20000005,70.79999852 L2.20000005,53.99999928 C1,53.99999928 0,54.89999926 0,56.09999919 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="lED1TSZF5niX4ra6"
                        d="M0,85.40000153 L0,111.40000153 C0,112.60000157 1,113.60000157 2.20000005,113.60000157 L2.20000005,83.30000234 C1,83.30000234 0,84.20000231 0,85.40000224 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hQoHW4klnEWgJ7ZV"
                        d="M0,122.40000153 L0,148.40000153 C0,149.60000157 1,150.60000157 2.20000005,150.60000157 L2.20000005,120.20000196 C1,120.20000196 0,121.20000196 0,122.400002 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="Gt7QuQ4BNnpTM3tk"
                        d="M204.1000061,93.30000305 L204.1000061,142.00000381 C205.30000615,142.00000381 206.30000615,141.00000381 206.30000615,139.80000377 L206.30000615,95.50000453 C206.30000615,94.30000448 205.30000615,93.30000448 204.1000061,93.30000448 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hPuclasitIuFFt7k"
                        d="M204.1000061,93.30000305 L204.1000061,29.90000153 C204.1000061,27.80000162 203.9000061,25.80000162 203.50000608,23.90000153 C203.30000608,22.90000153 203.10000607,22.00000155 202.80000609,21.00000143 C202.40000609,19.60000145 201.80000609,18.20000148 201.20000607,16.90000153 C200.80000606,16.00000155 200.30000609,15.20000148 199.80000609,14.40000153 C199.30000609,13.60000157 198.80000609,12.8000015 198.20000607,12.00000143 C197.30000609,10.80000138 196.40000612,9.80000138 195.40000612,8.70000148 C194.70000613,8.00000149 194.00000614,7.40000153 193.30000621,6.8000015 C188.1000064,2.50000131 181.50000602,0.00000131 174.30000621,0.00000131 L32.10000926,0.00000131 C24.90000945,0.00000131 18.20000964,2.60000122 13.10000926,6.8000015 C12.40000927,7.40000153 11.70000929,8.10000145 11.00000936,8.70000148 C10.00000936,9.70000148 9.00000936,10.80000138 8.20000941,12.00000143 C7.60000938,12.80000144 7.10000938,13.60000145 6.60000938,14.40000153 C6.10000938,15.2000016 5.60000938,16.10000157 5.20000941,16.90000153 C4.50000942,18.3000015 4.00000936,19.60000157 3.50000936,21.00000143 C3.20000935,22.00000143 3.00000936,22.90000141 2.80000937,23.90000153 C2.40000936,25.8000015 2.20000935,27.90000153 2.20000935,29.90000153 L2.20000935,378.19998932 C2.20000935,394.69998932 15.60000896,408.09998894 32.10000896,408.09998894 L174.20001507,408.09998894 C190.70001507,408.09998894 204.10001469,394.69998932 204.10001469,378.19998932 L204.10001469,93.29999542 Z M202.10001469,93.29999542 L202.10001469,378.29999542 C202.10001469,393.69999504 189.60001469,406.19999504 174.20001507,406.19999504 L32.10000896,406.19999504 C16.70000935,406.19999504 4.20000935,393.69999504 4.20000935,378.29999542 L4.20000935,30.00000763 C4.20000935,14.50000763 16.70000935,2.00000763 32.10000896,2.00000763 L174.20001507,2.00000763 C189.60001469,2.00000763 202.10001469,14.50000763 202.10001469,29.90000725 L202.10001469,93.30000877 Z"
                        style={{ fill: "#fce9d8", opacity: 1.0 }}
                      />
                    </g>
                  </svg>
                </div>
                </div>
                <div className="col-sm-3 p-4">
                  <div className="mobile-video-wrap">
                  <div className="soundButton">
                    <input
                      className="videoSeekInput"
                      type="range"
                      min={0}
                      max="59.6"
                      step="any"
                      defaultValue={0}
                    />
                    <svg
                      className="soundOnSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "none" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 5.102L8.778 8.35a2.5 2.5 0 0 1-1.643.616H4.5v6.065h2.632a2.5 2.5 0 0 1 1.644.617l3.724 3.25V5.102zM14 19.998c0 .858-1.01 1.318-1.658.753L7.79 16.778a1 1 0 0 0-.658-.247H4a1 1 0 0 1-1-1V8.466a1 1 0 0 1 1-1h3.135a1 1 0 0 0 .657-.246l4.55-3.971C12.99 2.684 14 3.143 14 4.002v15.996zM15.25 7a.75.75 0 0 1 .75-.75 5.75 5.75 0 0 1 0 11.5.75.75 0 0 1 0-1.5 4.25 4.25 0 0 0 0-8.5.75.75 0 0 1-.75-.75zM16 9.25a.75.75 0 0 0 0 1.5 1.25 1.25 0 1 1 0 2.5.75.75 0 0 0 0 1.5 2.75 2.75 0 1 0 0-5.5z"
                        fill="currentColor"
                      />
                    </svg>
                    <svg
                      className="soundMutedSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "block" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5 5.08l-3.742 3.171a2.5 2.5 0 01-.243.182L15.5 12.4V5.08zm1.5 8.814V4.002a1 1 0 00-1.646-.763l-4.566 3.868a1 1 0 01-.398.206L6.53 3.468a.75.75 0 10-1.06 1.063l14 13.94a.75.75 0 101.06-1.063L17 13.894zM6.293 7.637l1.208 1.207H7.5v5.8h2.642a2.5 2.5 0 011.616.593l3.742 3.17v-1.564l1.5 1.5v1.143a1 1 0 01-1.646.763l-4.566-3.868a1 1 0 00-.646-.237H7a1 1 0 01-1-1v-6.8c0-.276.112-.526.293-.707z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <svg
                    className="pauseSvg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  >
                    <rect
                      x={7}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                    <rect
                      x={14}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                  </svg>
                  <svg
                    className="playbtn"
                    onClick={togglePlay}
                    width={24}
                    height={24}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "block" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.248 4.212l11.05 6.574c.694.412.91 1.29.483 1.961-.121.19-.287.35-.483.467l-11.05 6.574c-.694.413-1.602.204-2.03-.467A1.39 1.39 0 0 1 6 18.574V5.426C6 4.638 6.66 4 7.475 4c.273 0 .54.073.773.212z"
                      fill="currentColor"
                    />
                  </svg>
                  <svg id="ZDipjYY5Io7HK7ka" viewBox="0 0 206.3 408.2">
                    <g
                      id="iW53AWUqGRXrddSj"
                      style={{ transform: "scale(1, 1)" }}
                    >
                      <path
                        id="l9n5lZ6G2hMsIERi"
                        d="M174.19999695,2 L32.09999084,2 C16.69999123,2 4.19999123,14.5 4.19999123,29.89999962 L4.19999123,378.19998741 C4.19999123,393.59998703 16.69999123,406.09998703 32.09999084,406.09998703 L174.19999695,406.09998703 C189.59999657,406.09998703 202.09999657,393.59998703 202.09999657,378.19998741 L202.09999657,29.89999962 C202.09999657,14.5 189.59999657,2 174.19999695,2 Z M126.89999771,14.80000019 C128.29999769,14.80000019 129.39999771,15.90000021 129.39999771,17.30000019 C129.39999771,18.70000017 128.29999769,19.80000019 126.89999771,19.80000019 C125.49999774,19.80000019 124.39999771,18.70000017 124.39999771,17.30000019 C124.39999771,15.90000021 125.59999776,14.80000019 126.89999771,14.80000019 Z M89.99999619,15.6000002 L113.99999619,15.6000002 C114.89999616,15.6000002 115.69999623,16.30000019 115.69999623,17.30000025 C115.69999623,18.30000031 114.89999622,19.0000003 113.99999619,19.0000003 L89.99999619,19.0000003 C89.09999621,19.0000003 88.29999614,18.30000031 88.29999614,17.30000025 C88.29999614,16.30000019 89.09999615,15.6000002 89.99999619,15.6000002 Z M191.89999771,379.80001241 C191.89999771,388.90001279 184.49999762,396.30001241 175.39999771,396.30001241 L31.09999466,396.30001241 C21.99999428,396.30001241 14.59999466,388.90001231 14.59999466,379.80001241 L14.59999466,28.80001241 C14.59999466,19.70001203 21.99999475,12.30001241 31.09999466,12.30001241 L52.59999466,12.30001241 L52.59999466,16.80001241 C52.59999466,21.90001231 56.79999447,26.1000126 61.89999485,26.1000126 L144.5999918,26.1000126 C149.6999917,26.1000126 153.89999199,21.90001279 153.89999199,16.80001241 L153.89999199,12.30001241 L175.29999161,12.30001241 C184.39999199,12.30001241 191.79999161,19.70001251 191.79999161,28.80001241 L191.79999161,379.80001241 Z"
                        style={{ fill: "#000000", opacity: 1.0 }}
                      />
                      <foreignObject
                        id="Pc6e4uSnn76we6ea"
                        style={{ width: "206.3px", height: "408.2px" }}
                      >
                        <div
                          id="MkIFM5vtu7uAnxiZ"
                          style={{
                            clipPath:
                              'path("M175.30000305,12.30000019 L153.90000343,12.30000019 L153.90000343,16.80000019 C153.90000343,21.9000001 149.70000362,26.10000038 144.60000324,26.10000038 L62.00000477,26.10000038 C56.90000486,26.10000038 52.70000458,21.90000057 52.70000458,16.80000019 L52.70000458,12.30000019 L31.1000042,12.30000019 C22.00000381,12.30000019 14.6000042,19.70000029 14.6000042,28.80000019 L14.6000042,379.80000019 C14.6000042,388.90000057 22.00000429,396.30000019 31.1000042,396.30000019 L175.30000114,396.30000019 C184.40000153,396.30000019 191.80000114,388.9000001 191.80000114,379.80000019 L191.80000114,28.80000019 C191.90000115,19.69999981 184.50000095,12.30000019 175.30000114,12.30000019 Z")',
                          }}
                        >
                          <div
                            id="i4KmmULDmpasgBey"
                            style={{
                              transform: "scale(1, 1)",
                              transformOrigin: "103px 204.5px",
                            }}
                          >
                            <video
                              className="myVideo"
                              controls
                              autoPlay
                              playsInline
                              preload="none"
                              muted
                              data-controls-width="100%"
                              data-controls-height="100%"
                              data-controls-top="0%"
                              data-controls-left="0%"
                            >
                              <source src={vedio6} type="video/mp4" />
                              Your browser does not support the video element.
                              Kindly update it to latest version.
                            </video>
                          </div>
                        </div>
                      </foreignObject>
                      <path
                        id="TNIQYNLWtoEOZu6x"
                        d="M114,15.60000038 L90,15.60000038 C89.10000002,15.60000038 88.29999995,16.30000037 88.29999995,17.30000043 C88.29999995,18.30000049 89.09999996,19.00000048 90,19.00000048 L114,19.00000048 C114.89999998,19.00000048 115.70000005,18.30000049 115.70000005,17.30000043 C115.70000005,16.30000037 114.90000004,15.60000038 114,15.60000038 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="t5mdwujubx3qVrkk"
                        d="M126.90000153,14.80000019 C126.00646995,14.79944832 125.18057651,15.2758248 124.73365133,16.049554 C124.28672615,16.8232832 124.28672615,17.77671623 124.73365133,18.55044543 C125.18057651,19.32417462 126.00646995,19.80055111 126.90000153,19.79999924 C127.79353311,19.80055111 128.61942655,19.32417462 129.06635172,18.55044543 C129.5132769,17.77671623 129.5132769,16.8232832 129.06635172,16.049554 C128.61942655,15.2758248 127.79353311,14.79944832 126.90000153,14.80000019 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="ukTKr9pKfwF2Ydfy"
                        d="M0,56.09999847 L0,68.59999847 C0,69.79999852 1,70.79999852 2.20000005,70.79999852 L2.20000005,53.99999928 C1,53.99999928 0,54.89999926 0,56.09999919 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="lED1TSZF5niX4ra6"
                        d="M0,85.40000153 L0,111.40000153 C0,112.60000157 1,113.60000157 2.20000005,113.60000157 L2.20000005,83.30000234 C1,83.30000234 0,84.20000231 0,85.40000224 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hQoHW4klnEWgJ7ZV"
                        d="M0,122.40000153 L0,148.40000153 C0,149.60000157 1,150.60000157 2.20000005,150.60000157 L2.20000005,120.20000196 C1,120.20000196 0,121.20000196 0,122.400002 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="Gt7QuQ4BNnpTM3tk"
                        d="M204.1000061,93.30000305 L204.1000061,142.00000381 C205.30000615,142.00000381 206.30000615,141.00000381 206.30000615,139.80000377 L206.30000615,95.50000453 C206.30000615,94.30000448 205.30000615,93.30000448 204.1000061,93.30000448 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hPuclasitIuFFt7k"
                        d="M204.1000061,93.30000305 L204.1000061,29.90000153 C204.1000061,27.80000162 203.9000061,25.80000162 203.50000608,23.90000153 C203.30000608,22.90000153 203.10000607,22.00000155 202.80000609,21.00000143 C202.40000609,19.60000145 201.80000609,18.20000148 201.20000607,16.90000153 C200.80000606,16.00000155 200.30000609,15.20000148 199.80000609,14.40000153 C199.30000609,13.60000157 198.80000609,12.8000015 198.20000607,12.00000143 C197.30000609,10.80000138 196.40000612,9.80000138 195.40000612,8.70000148 C194.70000613,8.00000149 194.00000614,7.40000153 193.30000621,6.8000015 C188.1000064,2.50000131 181.50000602,0.00000131 174.30000621,0.00000131 L32.10000926,0.00000131 C24.90000945,0.00000131 18.20000964,2.60000122 13.10000926,6.8000015 C12.40000927,7.40000153 11.70000929,8.10000145 11.00000936,8.70000148 C10.00000936,9.70000148 9.00000936,10.80000138 8.20000941,12.00000143 C7.60000938,12.80000144 7.10000938,13.60000145 6.60000938,14.40000153 C6.10000938,15.2000016 5.60000938,16.10000157 5.20000941,16.90000153 C4.50000942,18.3000015 4.00000936,19.60000157 3.50000936,21.00000143 C3.20000935,22.00000143 3.00000936,22.90000141 2.80000937,23.90000153 C2.40000936,25.8000015 2.20000935,27.90000153 2.20000935,29.90000153 L2.20000935,378.19998932 C2.20000935,394.69998932 15.60000896,408.09998894 32.10000896,408.09998894 L174.20001507,408.09998894 C190.70001507,408.09998894 204.10001469,394.69998932 204.10001469,378.19998932 L204.10001469,93.29999542 Z M202.10001469,93.29999542 L202.10001469,378.29999542 C202.10001469,393.69999504 189.60001469,406.19999504 174.20001507,406.19999504 L32.10000896,406.19999504 C16.70000935,406.19999504 4.20000935,393.69999504 4.20000935,378.29999542 L4.20000935,30.00000763 C4.20000935,14.50000763 16.70000935,2.00000763 32.10000896,2.00000763 L174.20001507,2.00000763 C189.60001469,2.00000763 202.10001469,14.50000763 202.10001469,29.90000725 L202.10001469,93.30000877 Z"
                        style={{ fill: "#fce9d8", opacity: 1.0 }}
                      />
                    </g>
                  </svg>
                </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-sm-3 p-4">
                  <div className="mobile-video-wrap">
                  <div className="soundButton">
                    <input
                      className="videoSeekInput"
                      type="range"
                      min={0}
                      max="59.6"
                      step="any"
                      defaultValue={0}
                    />
                    <svg
                      className="soundOnSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "none" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 5.102L8.778 8.35a2.5 2.5 0 0 1-1.643.616H4.5v6.065h2.632a2.5 2.5 0 0 1 1.644.617l3.724 3.25V5.102zM14 19.998c0 .858-1.01 1.318-1.658.753L7.79 16.778a1 1 0 0 0-.658-.247H4a1 1 0 0 1-1-1V8.466a1 1 0 0 1 1-1h3.135a1 1 0 0 0 .657-.246l4.55-3.971C12.99 2.684 14 3.143 14 4.002v15.996zM15.25 7a.75.75 0 0 1 .75-.75 5.75 5.75 0 0 1 0 11.5.75.75 0 0 1 0-1.5 4.25 4.25 0 0 0 0-8.5.75.75 0 0 1-.75-.75zM16 9.25a.75.75 0 0 0 0 1.5 1.25 1.25 0 1 1 0 2.5.75.75 0 0 0 0 1.5 2.75 2.75 0 1 0 0-5.5z"
                        fill="currentColor"
                      />
                    </svg>
                    <svg
                      className="soundMutedSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "block" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5 5.08l-3.742 3.171a2.5 2.5 0 01-.243.182L15.5 12.4V5.08zm1.5 8.814V4.002a1 1 0 00-1.646-.763l-4.566 3.868a1 1 0 01-.398.206L6.53 3.468a.75.75 0 10-1.06 1.063l14 13.94a.75.75 0 101.06-1.063L17 13.894zM6.293 7.637l1.208 1.207H7.5v5.8h2.642a2.5 2.5 0 011.616.593l3.742 3.17v-1.564l1.5 1.5v1.143a1 1 0 01-1.646.763l-4.566-3.868a1 1 0 00-.646-.237H7a1 1 0 01-1-1v-6.8c0-.276.112-.526.293-.707z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <svg
                    className="pauseSvg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  >
                    <rect
                      x={7}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                    <rect
                      x={14}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                  </svg>
                  <svg
                    className="playbtn"
                    onClick={togglePlay}
                    width={24}
                    height={24}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "block" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.248 4.212l11.05 6.574c.694.412.91 1.29.483 1.961-.121.19-.287.35-.483.467l-11.05 6.574c-.694.413-1.602.204-2.03-.467A1.39 1.39 0 0 1 6 18.574V5.426C6 4.638 6.66 4 7.475 4c.273 0 .54.073.773.212z"
                      fill="currentColor"
                    />
                  </svg>
                  <svg id="ZDipjYY5Io7HK7ka" viewBox="0 0 206.3 408.2">
                    <g
                      id="iW53AWUqGRXrddSj"
                      style={{ transform: "scale(1, 1)" }}
                    >
                      <path
                        id="l9n5lZ6G2hMsIERi"
                        d="M174.19999695,2 L32.09999084,2 C16.69999123,2 4.19999123,14.5 4.19999123,29.89999962 L4.19999123,378.19998741 C4.19999123,393.59998703 16.69999123,406.09998703 32.09999084,406.09998703 L174.19999695,406.09998703 C189.59999657,406.09998703 202.09999657,393.59998703 202.09999657,378.19998741 L202.09999657,29.89999962 C202.09999657,14.5 189.59999657,2 174.19999695,2 Z M126.89999771,14.80000019 C128.29999769,14.80000019 129.39999771,15.90000021 129.39999771,17.30000019 C129.39999771,18.70000017 128.29999769,19.80000019 126.89999771,19.80000019 C125.49999774,19.80000019 124.39999771,18.70000017 124.39999771,17.30000019 C124.39999771,15.90000021 125.59999776,14.80000019 126.89999771,14.80000019 Z M89.99999619,15.6000002 L113.99999619,15.6000002 C114.89999616,15.6000002 115.69999623,16.30000019 115.69999623,17.30000025 C115.69999623,18.30000031 114.89999622,19.0000003 113.99999619,19.0000003 L89.99999619,19.0000003 C89.09999621,19.0000003 88.29999614,18.30000031 88.29999614,17.30000025 C88.29999614,16.30000019 89.09999615,15.6000002 89.99999619,15.6000002 Z M191.89999771,379.80001241 C191.89999771,388.90001279 184.49999762,396.30001241 175.39999771,396.30001241 L31.09999466,396.30001241 C21.99999428,396.30001241 14.59999466,388.90001231 14.59999466,379.80001241 L14.59999466,28.80001241 C14.59999466,19.70001203 21.99999475,12.30001241 31.09999466,12.30001241 L52.59999466,12.30001241 L52.59999466,16.80001241 C52.59999466,21.90001231 56.79999447,26.1000126 61.89999485,26.1000126 L144.5999918,26.1000126 C149.6999917,26.1000126 153.89999199,21.90001279 153.89999199,16.80001241 L153.89999199,12.30001241 L175.29999161,12.30001241 C184.39999199,12.30001241 191.79999161,19.70001251 191.79999161,28.80001241 L191.79999161,379.80001241 Z"
                        style={{ fill: "#000000", opacity: 1.0 }}
                      />
                      <foreignObject
                        id="Pc6e4uSnn76we6ea"
                        style={{ width: "206.3px", height: "408.2px" }}
                      >
                        <div
                          id="MkIFM5vtu7uAnxiZ"
                          style={{
                            clipPath:
                              'path("M175.30000305,12.30000019 L153.90000343,12.30000019 L153.90000343,16.80000019 C153.90000343,21.9000001 149.70000362,26.10000038 144.60000324,26.10000038 L62.00000477,26.10000038 C56.90000486,26.10000038 52.70000458,21.90000057 52.70000458,16.80000019 L52.70000458,12.30000019 L31.1000042,12.30000019 C22.00000381,12.30000019 14.6000042,19.70000029 14.6000042,28.80000019 L14.6000042,379.80000019 C14.6000042,388.90000057 22.00000429,396.30000019 31.1000042,396.30000019 L175.30000114,396.30000019 C184.40000153,396.30000019 191.80000114,388.9000001 191.80000114,379.80000019 L191.80000114,28.80000019 C191.90000115,19.69999981 184.50000095,12.30000019 175.30000114,12.30000019 Z")',
                          }}
                        >
                          <div
                            id="i4KmmULDmpasgBey"
                            style={{
                              transform: "scale(1, 1)",
                              transformOrigin: "103px 204.5px",
                            }}
                          >
                            <video
                              className="myVideo"
                              controls
                              autoPlay
                              playsInline
                              preload="none"
                              muted
                              data-controls-width="100%"
                              data-controls-height="100%"
                              data-controls-top="0%"
                              data-controls-left="0%"
                            >
                              {/* /////////////////////////////////////////////// */}
                              <source src={vedio7} type="video/mp4" />
                              Your browser does not support the video element.
                              Kindly update it to latest version.
                            </video>
                          </div>
                        </div>
                      </foreignObject>
                      <path
                        id="TNIQYNLWtoEOZu6x"
                        d="M114,15.60000038 L90,15.60000038 C89.10000002,15.60000038 88.29999995,16.30000037 88.29999995,17.30000043 C88.29999995,18.30000049 89.09999996,19.00000048 90,19.00000048 L114,19.00000048 C114.89999998,19.00000048 115.70000005,18.30000049 115.70000005,17.30000043 C115.70000005,16.30000037 114.90000004,15.60000038 114,15.60000038 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="t5mdwujubx3qVrkk"
                        d="M126.90000153,14.80000019 C126.00646995,14.79944832 125.18057651,15.2758248 124.73365133,16.049554 C124.28672615,16.8232832 124.28672615,17.77671623 124.73365133,18.55044543 C125.18057651,19.32417462 126.00646995,19.80055111 126.90000153,19.79999924 C127.79353311,19.80055111 128.61942655,19.32417462 129.06635172,18.55044543 C129.5132769,17.77671623 129.5132769,16.8232832 129.06635172,16.049554 C128.61942655,15.2758248 127.79353311,14.79944832 126.90000153,14.80000019 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="ukTKr9pKfwF2Ydfy"
                        d="M0,56.09999847 L0,68.59999847 C0,69.79999852 1,70.79999852 2.20000005,70.79999852 L2.20000005,53.99999928 C1,53.99999928 0,54.89999926 0,56.09999919 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="lED1TSZF5niX4ra6"
                        d="M0,85.40000153 L0,111.40000153 C0,112.60000157 1,113.60000157 2.20000005,113.60000157 L2.20000005,83.30000234 C1,83.30000234 0,84.20000231 0,85.40000224 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hQoHW4klnEWgJ7ZV"
                        d="M0,122.40000153 L0,148.40000153 C0,149.60000157 1,150.60000157 2.20000005,150.60000157 L2.20000005,120.20000196 C1,120.20000196 0,121.20000196 0,122.400002 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="Gt7QuQ4BNnpTM3tk"
                        d="M204.1000061,93.30000305 L204.1000061,142.00000381 C205.30000615,142.00000381 206.30000615,141.00000381 206.30000615,139.80000377 L206.30000615,95.50000453 C206.30000615,94.30000448 205.30000615,93.30000448 204.1000061,93.30000448 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hPuclasitIuFFt7k"
                        d="M204.1000061,93.30000305 L204.1000061,29.90000153 C204.1000061,27.80000162 203.9000061,25.80000162 203.50000608,23.90000153 C203.30000608,22.90000153 203.10000607,22.00000155 202.80000609,21.00000143 C202.40000609,19.60000145 201.80000609,18.20000148 201.20000607,16.90000153 C200.80000606,16.00000155 200.30000609,15.20000148 199.80000609,14.40000153 C199.30000609,13.60000157 198.80000609,12.8000015 198.20000607,12.00000143 C197.30000609,10.80000138 196.40000612,9.80000138 195.40000612,8.70000148 C194.70000613,8.00000149 194.00000614,7.40000153 193.30000621,6.8000015 C188.1000064,2.50000131 181.50000602,0.00000131 174.30000621,0.00000131 L32.10000926,0.00000131 C24.90000945,0.00000131 18.20000964,2.60000122 13.10000926,6.8000015 C12.40000927,7.40000153 11.70000929,8.10000145 11.00000936,8.70000148 C10.00000936,9.70000148 9.00000936,10.80000138 8.20000941,12.00000143 C7.60000938,12.80000144 7.10000938,13.60000145 6.60000938,14.40000153 C6.10000938,15.2000016 5.60000938,16.10000157 5.20000941,16.90000153 C4.50000942,18.3000015 4.00000936,19.60000157 3.50000936,21.00000143 C3.20000935,22.00000143 3.00000936,22.90000141 2.80000937,23.90000153 C2.40000936,25.8000015 2.20000935,27.90000153 2.20000935,29.90000153 L2.20000935,378.19998932 C2.20000935,394.69998932 15.60000896,408.09998894 32.10000896,408.09998894 L174.20001507,408.09998894 C190.70001507,408.09998894 204.10001469,394.69998932 204.10001469,378.19998932 L204.10001469,93.29999542 Z M202.10001469,93.29999542 L202.10001469,378.29999542 C202.10001469,393.69999504 189.60001469,406.19999504 174.20001507,406.19999504 L32.10000896,406.19999504 C16.70000935,406.19999504 4.20000935,393.69999504 4.20000935,378.29999542 L4.20000935,30.00000763 C4.20000935,14.50000763 16.70000935,2.00000763 32.10000896,2.00000763 L174.20001507,2.00000763 C189.60001469,2.00000763 202.10001469,14.50000763 202.10001469,29.90000725 L202.10001469,93.30000877 Z"
                        style={{ fill: "#fce9d8", opacity: 1.0 }}
                      />
                    </g>
                  </svg>
                </div>
                </div>
                <div className="col-sm-3 p-4">
                  <div className="mobile-video-wrap">
                  <div className="soundButton">
                    <input
                      className="videoSeekInput"
                      type="range"
                      min={0}
                      max="59.6"
                      step="any"
                      defaultValue={0}
                    />
                    <svg
                      className="soundOnSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "none" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 5.102L8.778 8.35a2.5 2.5 0 0 1-1.643.616H4.5v6.065h2.632a2.5 2.5 0 0 1 1.644.617l3.724 3.25V5.102zM14 19.998c0 .858-1.01 1.318-1.658.753L7.79 16.778a1 1 0 0 0-.658-.247H4a1 1 0 0 1-1-1V8.466a1 1 0 0 1 1-1h3.135a1 1 0 0 0 .657-.246l4.55-3.971C12.99 2.684 14 3.143 14 4.002v15.996zM15.25 7a.75.75 0 0 1 .75-.75 5.75 5.75 0 0 1 0 11.5.75.75 0 0 1 0-1.5 4.25 4.25 0 0 0 0-8.5.75.75 0 0 1-.75-.75zM16 9.25a.75.75 0 0 0 0 1.5 1.25 1.25 0 1 1 0 2.5.75.75 0 0 0 0 1.5 2.75 2.75 0 1 0 0-5.5z"
                        fill="currentColor"
                      />
                    </svg>
                    <svg
                      className="soundMutedSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "block" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5 5.08l-3.742 3.171a2.5 2.5 0 01-.243.182L15.5 12.4V5.08zm1.5 8.814V4.002a1 1 0 00-1.646-.763l-4.566 3.868a1 1 0 01-.398.206L6.53 3.468a.75.75 0 10-1.06 1.063l14 13.94a.75.75 0 101.06-1.063L17 13.894zM6.293 7.637l1.208 1.207H7.5v5.8h2.642a2.5 2.5 0 011.616.593l3.742 3.17v-1.564l1.5 1.5v1.143a1 1 0 01-1.646.763l-4.566-3.868a1 1 0 00-.646-.237H7a1 1 0 01-1-1v-6.8c0-.276.112-.526.293-.707z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <svg
                    className="pauseSvg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  >
                    <rect
                      x={7}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                    <rect
                      x={14}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                  </svg>
                  <svg
                    className="playbtn"
                    onClick={togglePlay}
                    width={24}
                    height={24}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "block" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.248 4.212l11.05 6.574c.694.412.91 1.29.483 1.961-.121.19-.287.35-.483.467l-11.05 6.574c-.694.413-1.602.204-2.03-.467A1.39 1.39 0 0 1 6 18.574V5.426C6 4.638 6.66 4 7.475 4c.273 0 .54.073.773.212z"
                      fill="currentColor"
                    />
                  </svg>
                  <svg id="ZDipjYY5Io7HK7ka" viewBox="0 0 206.3 408.2">
                    <g
                      id="iW53AWUqGRXrddSj"
                      style={{ transform: "scale(1, 1)" }}
                    >
                      <path
                        id="l9n5lZ6G2hMsIERi"
                        d="M174.19999695,2 L32.09999084,2 C16.69999123,2 4.19999123,14.5 4.19999123,29.89999962 L4.19999123,378.19998741 C4.19999123,393.59998703 16.69999123,406.09998703 32.09999084,406.09998703 L174.19999695,406.09998703 C189.59999657,406.09998703 202.09999657,393.59998703 202.09999657,378.19998741 L202.09999657,29.89999962 C202.09999657,14.5 189.59999657,2 174.19999695,2 Z M126.89999771,14.80000019 C128.29999769,14.80000019 129.39999771,15.90000021 129.39999771,17.30000019 C129.39999771,18.70000017 128.29999769,19.80000019 126.89999771,19.80000019 C125.49999774,19.80000019 124.39999771,18.70000017 124.39999771,17.30000019 C124.39999771,15.90000021 125.59999776,14.80000019 126.89999771,14.80000019 Z M89.99999619,15.6000002 L113.99999619,15.6000002 C114.89999616,15.6000002 115.69999623,16.30000019 115.69999623,17.30000025 C115.69999623,18.30000031 114.89999622,19.0000003 113.99999619,19.0000003 L89.99999619,19.0000003 C89.09999621,19.0000003 88.29999614,18.30000031 88.29999614,17.30000025 C88.29999614,16.30000019 89.09999615,15.6000002 89.99999619,15.6000002 Z M191.89999771,379.80001241 C191.89999771,388.90001279 184.49999762,396.30001241 175.39999771,396.30001241 L31.09999466,396.30001241 C21.99999428,396.30001241 14.59999466,388.90001231 14.59999466,379.80001241 L14.59999466,28.80001241 C14.59999466,19.70001203 21.99999475,12.30001241 31.09999466,12.30001241 L52.59999466,12.30001241 L52.59999466,16.80001241 C52.59999466,21.90001231 56.79999447,26.1000126 61.89999485,26.1000126 L144.5999918,26.1000126 C149.6999917,26.1000126 153.89999199,21.90001279 153.89999199,16.80001241 L153.89999199,12.30001241 L175.29999161,12.30001241 C184.39999199,12.30001241 191.79999161,19.70001251 191.79999161,28.80001241 L191.79999161,379.80001241 Z"
                        style={{ fill: "#000000", opacity: 1.0 }}
                      />
                      <foreignObject
                        id="Pc6e4uSnn76we6ea"
                        style={{ width: "206.3px", height: "408.2px" }}
                      >
                        <div
                          id="MkIFM5vtu7uAnxiZ"
                          style={{
                            clipPath:
                              'path("M175.30000305,12.30000019 L153.90000343,12.30000019 L153.90000343,16.80000019 C153.90000343,21.9000001 149.70000362,26.10000038 144.60000324,26.10000038 L62.00000477,26.10000038 C56.90000486,26.10000038 52.70000458,21.90000057 52.70000458,16.80000019 L52.70000458,12.30000019 L31.1000042,12.30000019 C22.00000381,12.30000019 14.6000042,19.70000029 14.6000042,28.80000019 L14.6000042,379.80000019 C14.6000042,388.90000057 22.00000429,396.30000019 31.1000042,396.30000019 L175.30000114,396.30000019 C184.40000153,396.30000019 191.80000114,388.9000001 191.80000114,379.80000019 L191.80000114,28.80000019 C191.90000115,19.69999981 184.50000095,12.30000019 175.30000114,12.30000019 Z")',
                          }}
                        >
                          <div
                            id="i4KmmULDmpasgBey"
                            style={{
                              transform: "scale(1, 1)",
                              transformOrigin: "103px 204.5px",
                            }}
                          >
                            <video
                              className="myVideo"
                              controls
                              autoPlay
                              playsInline
                              preload="none"
                              muted
                              data-controls-width="100%"
                              data-controls-height="100%"
                              data-controls-top="0%"
                              data-controls-left="0%"
                            >
                              <source src={vedio8} type="video/mp4" />
                              Your browser does not support the video element.
                              Kindly update it to latest version.
                            </video>
                          </div>
                        </div>
                      </foreignObject>
                      <path
                        id="TNIQYNLWtoEOZu6x"
                        d="M114,15.60000038 L90,15.60000038 C89.10000002,15.60000038 88.29999995,16.30000037 88.29999995,17.30000043 C88.29999995,18.30000049 89.09999996,19.00000048 90,19.00000048 L114,19.00000048 C114.89999998,19.00000048 115.70000005,18.30000049 115.70000005,17.30000043 C115.70000005,16.30000037 114.90000004,15.60000038 114,15.60000038 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="t5mdwujubx3qVrkk"
                        d="M126.90000153,14.80000019 C126.00646995,14.79944832 125.18057651,15.2758248 124.73365133,16.049554 C124.28672615,16.8232832 124.28672615,17.77671623 124.73365133,18.55044543 C125.18057651,19.32417462 126.00646995,19.80055111 126.90000153,19.79999924 C127.79353311,19.80055111 128.61942655,19.32417462 129.06635172,18.55044543 C129.5132769,17.77671623 129.5132769,16.8232832 129.06635172,16.049554 C128.61942655,15.2758248 127.79353311,14.79944832 126.90000153,14.80000019 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="ukTKr9pKfwF2Ydfy"
                        d="M0,56.09999847 L0,68.59999847 C0,69.79999852 1,70.79999852 2.20000005,70.79999852 L2.20000005,53.99999928 C1,53.99999928 0,54.89999926 0,56.09999919 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="lED1TSZF5niX4ra6"
                        d="M0,85.40000153 L0,111.40000153 C0,112.60000157 1,113.60000157 2.20000005,113.60000157 L2.20000005,83.30000234 C1,83.30000234 0,84.20000231 0,85.40000224 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hQoHW4klnEWgJ7ZV"
                        d="M0,122.40000153 L0,148.40000153 C0,149.60000157 1,150.60000157 2.20000005,150.60000157 L2.20000005,120.20000196 C1,120.20000196 0,121.20000196 0,122.400002 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="Gt7QuQ4BNnpTM3tk"
                        d="M204.1000061,93.30000305 L204.1000061,142.00000381 C205.30000615,142.00000381 206.30000615,141.00000381 206.30000615,139.80000377 L206.30000615,95.50000453 C206.30000615,94.30000448 205.30000615,93.30000448 204.1000061,93.30000448 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hPuclasitIuFFt7k"
                        d="M204.1000061,93.30000305 L204.1000061,29.90000153 C204.1000061,27.80000162 203.9000061,25.80000162 203.50000608,23.90000153 C203.30000608,22.90000153 203.10000607,22.00000155 202.80000609,21.00000143 C202.40000609,19.60000145 201.80000609,18.20000148 201.20000607,16.90000153 C200.80000606,16.00000155 200.30000609,15.20000148 199.80000609,14.40000153 C199.30000609,13.60000157 198.80000609,12.8000015 198.20000607,12.00000143 C197.30000609,10.80000138 196.40000612,9.80000138 195.40000612,8.70000148 C194.70000613,8.00000149 194.00000614,7.40000153 193.30000621,6.8000015 C188.1000064,2.50000131 181.50000602,0.00000131 174.30000621,0.00000131 L32.10000926,0.00000131 C24.90000945,0.00000131 18.20000964,2.60000122 13.10000926,6.8000015 C12.40000927,7.40000153 11.70000929,8.10000145 11.00000936,8.70000148 C10.00000936,9.70000148 9.00000936,10.80000138 8.20000941,12.00000143 C7.60000938,12.80000144 7.10000938,13.60000145 6.60000938,14.40000153 C6.10000938,15.2000016 5.60000938,16.10000157 5.20000941,16.90000153 C4.50000942,18.3000015 4.00000936,19.60000157 3.50000936,21.00000143 C3.20000935,22.00000143 3.00000936,22.90000141 2.80000937,23.90000153 C2.40000936,25.8000015 2.20000935,27.90000153 2.20000935,29.90000153 L2.20000935,378.19998932 C2.20000935,394.69998932 15.60000896,408.09998894 32.10000896,408.09998894 L174.20001507,408.09998894 C190.70001507,408.09998894 204.10001469,394.69998932 204.10001469,378.19998932 L204.10001469,93.29999542 Z M202.10001469,93.29999542 L202.10001469,378.29999542 C202.10001469,393.69999504 189.60001469,406.19999504 174.20001507,406.19999504 L32.10000896,406.19999504 C16.70000935,406.19999504 4.20000935,393.69999504 4.20000935,378.29999542 L4.20000935,30.00000763 C4.20000935,14.50000763 16.70000935,2.00000763 32.10000896,2.00000763 L174.20001507,2.00000763 C189.60001469,2.00000763 202.10001469,14.50000763 202.10001469,29.90000725 L202.10001469,93.30000877 Z"
                        style={{ fill: "#fce9d8", opacity: 1.0 }}
                      />
                    </g>
                  </svg>
                </div>
                </div>
                <div className="col-sm-3 p-4">
                  <div className="mobile-video-wrap">
                  <div className="soundButton">
                    <input
                      className="videoSeekInput"
                      type="range"
                      min={0}
                      max="59.6"
                      step="any"
                      defaultValue={0}
                    />
                    <svg
                      className="soundOnSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "none" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 5.102L8.778 8.35a2.5 2.5 0 0 1-1.643.616H4.5v6.065h2.632a2.5 2.5 0 0 1 1.644.617l3.724 3.25V5.102zM14 19.998c0 .858-1.01 1.318-1.658.753L7.79 16.778a1 1 0 0 0-.658-.247H4a1 1 0 0 1-1-1V8.466a1 1 0 0 1 1-1h3.135a1 1 0 0 0 .657-.246l4.55-3.971C12.99 2.684 14 3.143 14 4.002v15.996zM15.25 7a.75.75 0 0 1 .75-.75 5.75 5.75 0 0 1 0 11.5.75.75 0 0 1 0-1.5 4.25 4.25 0 0 0 0-8.5.75.75 0 0 1-.75-.75zM16 9.25a.75.75 0 0 0 0 1.5 1.25 1.25 0 1 1 0 2.5.75.75 0 0 0 0 1.5 2.75 2.75 0 1 0 0-5.5z"
                        fill="currentColor"
                      />
                    </svg>
                    <svg
                      className="soundMutedSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "block" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5 5.08l-3.742 3.171a2.5 2.5 0 01-.243.182L15.5 12.4V5.08zm1.5 8.814V4.002a1 1 0 00-1.646-.763l-4.566 3.868a1 1 0 01-.398.206L6.53 3.468a.75.75 0 10-1.06 1.063l14 13.94a.75.75 0 101.06-1.063L17 13.894zM6.293 7.637l1.208 1.207H7.5v5.8h2.642a2.5 2.5 0 011.616.593l3.742 3.17v-1.564l1.5 1.5v1.143a1 1 0 01-1.646.763l-4.566-3.868a1 1 0 00-.646-.237H7a1 1 0 01-1-1v-6.8c0-.276.112-.526.293-.707z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <svg
                    className="pauseSvg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  >
                    <rect
                      x={7}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                    <rect
                      x={14}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                  </svg>
                  <svg
                    className="playbtn"
                    onClick={togglePlay}
                    width={24}
                    height={24}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "block" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.248 4.212l11.05 6.574c.694.412.91 1.29.483 1.961-.121.19-.287.35-.483.467l-11.05 6.574c-.694.413-1.602.204-2.03-.467A1.39 1.39 0 0 1 6 18.574V5.426C6 4.638 6.66 4 7.475 4c.273 0 .54.073.773.212z"
                      fill="currentColor"
                    />
                  </svg>
                  <svg id="ZDipjYY5Io7HK7ka" viewBox="0 0 206.3 408.2">
                    <g
                      id="iW53AWUqGRXrddSj"
                      style={{ transform: "scale(1, 1)" }}
                    >
                      <path
                        id="l9n5lZ6G2hMsIERi"
                        d="M174.19999695,2 L32.09999084,2 C16.69999123,2 4.19999123,14.5 4.19999123,29.89999962 L4.19999123,378.19998741 C4.19999123,393.59998703 16.69999123,406.09998703 32.09999084,406.09998703 L174.19999695,406.09998703 C189.59999657,406.09998703 202.09999657,393.59998703 202.09999657,378.19998741 L202.09999657,29.89999962 C202.09999657,14.5 189.59999657,2 174.19999695,2 Z M126.89999771,14.80000019 C128.29999769,14.80000019 129.39999771,15.90000021 129.39999771,17.30000019 C129.39999771,18.70000017 128.29999769,19.80000019 126.89999771,19.80000019 C125.49999774,19.80000019 124.39999771,18.70000017 124.39999771,17.30000019 C124.39999771,15.90000021 125.59999776,14.80000019 126.89999771,14.80000019 Z M89.99999619,15.6000002 L113.99999619,15.6000002 C114.89999616,15.6000002 115.69999623,16.30000019 115.69999623,17.30000025 C115.69999623,18.30000031 114.89999622,19.0000003 113.99999619,19.0000003 L89.99999619,19.0000003 C89.09999621,19.0000003 88.29999614,18.30000031 88.29999614,17.30000025 C88.29999614,16.30000019 89.09999615,15.6000002 89.99999619,15.6000002 Z M191.89999771,379.80001241 C191.89999771,388.90001279 184.49999762,396.30001241 175.39999771,396.30001241 L31.09999466,396.30001241 C21.99999428,396.30001241 14.59999466,388.90001231 14.59999466,379.80001241 L14.59999466,28.80001241 C14.59999466,19.70001203 21.99999475,12.30001241 31.09999466,12.30001241 L52.59999466,12.30001241 L52.59999466,16.80001241 C52.59999466,21.90001231 56.79999447,26.1000126 61.89999485,26.1000126 L144.5999918,26.1000126 C149.6999917,26.1000126 153.89999199,21.90001279 153.89999199,16.80001241 L153.89999199,12.30001241 L175.29999161,12.30001241 C184.39999199,12.30001241 191.79999161,19.70001251 191.79999161,28.80001241 L191.79999161,379.80001241 Z"
                        style={{ fill: "#000000", opacity: 1.0 }}
                      />
                      <foreignObject
                        id="Pc6e4uSnn76we6ea"
                        style={{ width: "206.3px", height: "408.2px" }}
                      >
                        <div
                          id="MkIFM5vtu7uAnxiZ"
                          style={{
                            clipPath:
                              'path("M175.30000305,12.30000019 L153.90000343,12.30000019 L153.90000343,16.80000019 C153.90000343,21.9000001 149.70000362,26.10000038 144.60000324,26.10000038 L62.00000477,26.10000038 C56.90000486,26.10000038 52.70000458,21.90000057 52.70000458,16.80000019 L52.70000458,12.30000019 L31.1000042,12.30000019 C22.00000381,12.30000019 14.6000042,19.70000029 14.6000042,28.80000019 L14.6000042,379.80000019 C14.6000042,388.90000057 22.00000429,396.30000019 31.1000042,396.30000019 L175.30000114,396.30000019 C184.40000153,396.30000019 191.80000114,388.9000001 191.80000114,379.80000019 L191.80000114,28.80000019 C191.90000115,19.69999981 184.50000095,12.30000019 175.30000114,12.30000019 Z")',
                          }}
                        >
                          <div
                            id="i4KmmULDmpasgBey"
                            style={{
                              transform: "scale(1, 1)",
                              transformOrigin: "103px 204.5px",
                            }}
                          >
                            <video
                              className="myVideo"
                              controls
                              autoPlay
                              playsInline
                              preload="none"
                              muted
                              data-controls-width="100%"
                              data-controls-height="100%"
                              data-controls-top="0%"
                              data-controls-left="0%"
                            >
                              <source src={vedio9} type="video/mp4" />
                              {/* /////////////////////////////////////////////// */}
                              Your browser does not support the video element.
                              Kindly update it to latest version.
                            </video>
                          </div>
                        </div>
                      </foreignObject>
                      <path
                        id="TNIQYNLWtoEOZu6x"
                        d="M114,15.60000038 L90,15.60000038 C89.10000002,15.60000038 88.29999995,16.30000037 88.29999995,17.30000043 C88.29999995,18.30000049 89.09999996,19.00000048 90,19.00000048 L114,19.00000048 C114.89999998,19.00000048 115.70000005,18.30000049 115.70000005,17.30000043 C115.70000005,16.30000037 114.90000004,15.60000038 114,15.60000038 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="t5mdwujubx3qVrkk"
                        d="M126.90000153,14.80000019 C126.00646995,14.79944832 125.18057651,15.2758248 124.73365133,16.049554 C124.28672615,16.8232832 124.28672615,17.77671623 124.73365133,18.55044543 C125.18057651,19.32417462 126.00646995,19.80055111 126.90000153,19.79999924 C127.79353311,19.80055111 128.61942655,19.32417462 129.06635172,18.55044543 C129.5132769,17.77671623 129.5132769,16.8232832 129.06635172,16.049554 C128.61942655,15.2758248 127.79353311,14.79944832 126.90000153,14.80000019 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="ukTKr9pKfwF2Ydfy"
                        d="M0,56.09999847 L0,68.59999847 C0,69.79999852 1,70.79999852 2.20000005,70.79999852 L2.20000005,53.99999928 C1,53.99999928 0,54.89999926 0,56.09999919 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="lED1TSZF5niX4ra6"
                        d="M0,85.40000153 L0,111.40000153 C0,112.60000157 1,113.60000157 2.20000005,113.60000157 L2.20000005,83.30000234 C1,83.30000234 0,84.20000231 0,85.40000224 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hQoHW4klnEWgJ7ZV"
                        d="M0,122.40000153 L0,148.40000153 C0,149.60000157 1,150.60000157 2.20000005,150.60000157 L2.20000005,120.20000196 C1,120.20000196 0,121.20000196 0,122.400002 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="Gt7QuQ4BNnpTM3tk"
                        d="M204.1000061,93.30000305 L204.1000061,142.00000381 C205.30000615,142.00000381 206.30000615,141.00000381 206.30000615,139.80000377 L206.30000615,95.50000453 C206.30000615,94.30000448 205.30000615,93.30000448 204.1000061,93.30000448 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hPuclasitIuFFt7k"
                        d="M204.1000061,93.30000305 L204.1000061,29.90000153 C204.1000061,27.80000162 203.9000061,25.80000162 203.50000608,23.90000153 C203.30000608,22.90000153 203.10000607,22.00000155 202.80000609,21.00000143 C202.40000609,19.60000145 201.80000609,18.20000148 201.20000607,16.90000153 C200.80000606,16.00000155 200.30000609,15.20000148 199.80000609,14.40000153 C199.30000609,13.60000157 198.80000609,12.8000015 198.20000607,12.00000143 C197.30000609,10.80000138 196.40000612,9.80000138 195.40000612,8.70000148 C194.70000613,8.00000149 194.00000614,7.40000153 193.30000621,6.8000015 C188.1000064,2.50000131 181.50000602,0.00000131 174.30000621,0.00000131 L32.10000926,0.00000131 C24.90000945,0.00000131 18.20000964,2.60000122 13.10000926,6.8000015 C12.40000927,7.40000153 11.70000929,8.10000145 11.00000936,8.70000148 C10.00000936,9.70000148 9.00000936,10.80000138 8.20000941,12.00000143 C7.60000938,12.80000144 7.10000938,13.60000145 6.60000938,14.40000153 C6.10000938,15.2000016 5.60000938,16.10000157 5.20000941,16.90000153 C4.50000942,18.3000015 4.00000936,19.60000157 3.50000936,21.00000143 C3.20000935,22.00000143 3.00000936,22.90000141 2.80000937,23.90000153 C2.40000936,25.8000015 2.20000935,27.90000153 2.20000935,29.90000153 L2.20000935,378.19998932 C2.20000935,394.69998932 15.60000896,408.09998894 32.10000896,408.09998894 L174.20001507,408.09998894 C190.70001507,408.09998894 204.10001469,394.69998932 204.10001469,378.19998932 L204.10001469,93.29999542 Z M202.10001469,93.29999542 L202.10001469,378.29999542 C202.10001469,393.69999504 189.60001469,406.19999504 174.20001507,406.19999504 L32.10000896,406.19999504 C16.70000935,406.19999504 4.20000935,393.69999504 4.20000935,378.29999542 L4.20000935,30.00000763 C4.20000935,14.50000763 16.70000935,2.00000763 32.10000896,2.00000763 L174.20001507,2.00000763 C189.60001469,2.00000763 202.10001469,14.50000763 202.10001469,29.90000725 L202.10001469,93.30000877 Z"
                        style={{ fill: "#fce9d8", opacity: 1.0 }}
                      />
                    </g>
                  </svg>
                </div>
                </div>
                <div className="col-sm-3 p-4">
                  <div className="mobile-video-wrap">
                  <div className="soundButton">
                    <input
                      className="videoSeekInput"
                      type="range"
                      min={0}
                      max="59.6"
                      step="any"
                      defaultValue={0}
                    />
                    <svg
                      className="soundOnSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "none" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 5.102L8.778 8.35a2.5 2.5 0 0 1-1.643.616H4.5v6.065h2.632a2.5 2.5 0 0 1 1.644.617l3.724 3.25V5.102zM14 19.998c0 .858-1.01 1.318-1.658.753L7.79 16.778a1 1 0 0 0-.658-.247H4a1 1 0 0 1-1-1V8.466a1 1 0 0 1 1-1h3.135a1 1 0 0 0 .657-.246l4.55-3.971C12.99 2.684 14 3.143 14 4.002v15.996zM15.25 7a.75.75 0 0 1 .75-.75 5.75 5.75 0 0 1 0 11.5.75.75 0 0 1 0-1.5 4.25 4.25 0 0 0 0-8.5.75.75 0 0 1-.75-.75zM16 9.25a.75.75 0 0 0 0 1.5 1.25 1.25 0 1 1 0 2.5.75.75 0 0 0 0 1.5 2.75 2.75 0 1 0 0-5.5z"
                        fill="currentColor"
                      />
                    </svg>
                    <svg
                      className="soundMutedSvg"
                      width={24}
                      height={24}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: "block" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5 5.08l-3.742 3.171a2.5 2.5 0 01-.243.182L15.5 12.4V5.08zm1.5 8.814V4.002a1 1 0 00-1.646-.763l-4.566 3.868a1 1 0 01-.398.206L6.53 3.468a.75.75 0 10-1.06 1.063l14 13.94a.75.75 0 101.06-1.063L17 13.894zM6.293 7.637l1.208 1.207H7.5v5.8h2.642a2.5 2.5 0 011.616.593l3.742 3.17v-1.564l1.5 1.5v1.143a1 1 0 01-1.646.763l-4.566-3.868a1 1 0 00-.646-.237H7a1 1 0 01-1-1v-6.8c0-.276.112-.526.293-.707z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <svg
                    className="pauseSvg"
                    onClick={togglePlay}
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  >
                    <rect
                      x={7}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                    <rect
                      x={14}
                      y={5}
                      width={3}
                      height={14}
                      rx="1.5"
                      fill="currentColor"
                    />
                  </svg>
                  <svg
                    className="playbtn"
                    onClick={togglePlay}
                    width={24}
                    height={24}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "block" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.248 4.212l11.05 6.574c.694.412.91 1.29.483 1.961-.121.19-.287.35-.483.467l-11.05 6.574c-.694.413-1.602.204-2.03-.467A1.39 1.39 0 0 1 6 18.574V5.426C6 4.638 6.66 4 7.475 4c.273 0 .54.073.773.212z"
                      fill="currentColor"
                    />
                  </svg>
                  <svg id="ZDipjYY5Io7HK7ka" viewBox="0 0 206.3 408.2">
                    <g
                      id="iW53AWUqGRXrddSj"
                      style={{ transform: "scale(1, 1)" }}
                    >
                      <path
                        id="l9n5lZ6G2hMsIERi"
                        d="M174.19999695,2 L32.09999084,2 C16.69999123,2 4.19999123,14.5 4.19999123,29.89999962 L4.19999123,378.19998741 C4.19999123,393.59998703 16.69999123,406.09998703 32.09999084,406.09998703 L174.19999695,406.09998703 C189.59999657,406.09998703 202.09999657,393.59998703 202.09999657,378.19998741 L202.09999657,29.89999962 C202.09999657,14.5 189.59999657,2 174.19999695,2 Z M126.89999771,14.80000019 C128.29999769,14.80000019 129.39999771,15.90000021 129.39999771,17.30000019 C129.39999771,18.70000017 128.29999769,19.80000019 126.89999771,19.80000019 C125.49999774,19.80000019 124.39999771,18.70000017 124.39999771,17.30000019 C124.39999771,15.90000021 125.59999776,14.80000019 126.89999771,14.80000019 Z M89.99999619,15.6000002 L113.99999619,15.6000002 C114.89999616,15.6000002 115.69999623,16.30000019 115.69999623,17.30000025 C115.69999623,18.30000031 114.89999622,19.0000003 113.99999619,19.0000003 L89.99999619,19.0000003 C89.09999621,19.0000003 88.29999614,18.30000031 88.29999614,17.30000025 C88.29999614,16.30000019 89.09999615,15.6000002 89.99999619,15.6000002 Z M191.89999771,379.80001241 C191.89999771,388.90001279 184.49999762,396.30001241 175.39999771,396.30001241 L31.09999466,396.30001241 C21.99999428,396.30001241 14.59999466,388.90001231 14.59999466,379.80001241 L14.59999466,28.80001241 C14.59999466,19.70001203 21.99999475,12.30001241 31.09999466,12.30001241 L52.59999466,12.30001241 L52.59999466,16.80001241 C52.59999466,21.90001231 56.79999447,26.1000126 61.89999485,26.1000126 L144.5999918,26.1000126 C149.6999917,26.1000126 153.89999199,21.90001279 153.89999199,16.80001241 L153.89999199,12.30001241 L175.29999161,12.30001241 C184.39999199,12.30001241 191.79999161,19.70001251 191.79999161,28.80001241 L191.79999161,379.80001241 Z"
                        style={{ fill: "#000000", opacity: 1.0 }}
                      />
                      <foreignObject
                        id="Pc6e4uSnn76we6ea"
                        style={{ width: "206.3px", height: "408.2px" }}
                      >
                        <div
                          id="MkIFM5vtu7uAnxiZ"
                          style={{
                            clipPath:
                              'path("M175.30000305,12.30000019 L153.90000343,12.30000019 L153.90000343,16.80000019 C153.90000343,21.9000001 149.70000362,26.10000038 144.60000324,26.10000038 L62.00000477,26.10000038 C56.90000486,26.10000038 52.70000458,21.90000057 52.70000458,16.80000019 L52.70000458,12.30000019 L31.1000042,12.30000019 C22.00000381,12.30000019 14.6000042,19.70000029 14.6000042,28.80000019 L14.6000042,379.80000019 C14.6000042,388.90000057 22.00000429,396.30000019 31.1000042,396.30000019 L175.30000114,396.30000019 C184.40000153,396.30000019 191.80000114,388.9000001 191.80000114,379.80000019 L191.80000114,28.80000019 C191.90000115,19.69999981 184.50000095,12.30000019 175.30000114,12.30000019 Z")',
                          }}
                        >
                          <div
                            id="i4KmmULDmpasgBey"
                            style={{
                              transform: "scale(1, 1)",
                              transformOrigin: "103px 204.5px",
                            }}
                          >
                            <video
                              className="myVideo"
                              controls
                              autoPlay
                              playsInline
                              preload="none"
                              muted
                              data-controls-width="100%"
                              data-controls-height="100%"
                              data-controls-top="0%"
                              data-controls-left="0%"
                            >
                              <source src={vedio10} type="video/mp4" />
                            </video>
                          </div>
                        </div>
                      </foreignObject>
                      <path
                        id="TNIQYNLWtoEOZu6x"
                        d="M114,15.60000038 L90,15.60000038 C89.10000002,15.60000038 88.29999995,16.30000037 88.29999995,17.30000043 C88.29999995,18.30000049 89.09999996,19.00000048 90,19.00000048 L114,19.00000048 C114.89999998,19.00000048 115.70000005,18.30000049 115.70000005,17.30000043 C115.70000005,16.30000037 114.90000004,15.60000038 114,15.60000038 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="t5mdwujubx3qVrkk"
                        d="M126.90000153,14.80000019 C126.00646995,14.79944832 125.18057651,15.2758248 124.73365133,16.049554 C124.28672615,16.8232832 124.28672615,17.77671623 124.73365133,18.55044543 C125.18057651,19.32417462 126.00646995,19.80055111 126.90000153,19.79999924 C127.79353311,19.80055111 128.61942655,19.32417462 129.06635172,18.55044543 C129.5132769,17.77671623 129.5132769,16.8232832 129.06635172,16.049554 C128.61942655,15.2758248 127.79353311,14.79944832 126.90000153,14.80000019 Z"
                        style={{ fill: "#5b5b5b", opacity: 1.0 }}
                      />
                      <path
                        id="ukTKr9pKfwF2Ydfy"
                        d="M0,56.09999847 L0,68.59999847 C0,69.79999852 1,70.79999852 2.20000005,70.79999852 L2.20000005,53.99999928 C1,53.99999928 0,54.89999926 0,56.09999919 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="lED1TSZF5niX4ra6"
                        d="M0,85.40000153 L0,111.40000153 C0,112.60000157 1,113.60000157 2.20000005,113.60000157 L2.20000005,83.30000234 C1,83.30000234 0,84.20000231 0,85.40000224 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hQoHW4klnEWgJ7ZV"
                        d="M0,122.40000153 L0,148.40000153 C0,149.60000157 1,150.60000157 2.20000005,150.60000157 L2.20000005,120.20000196 C1,120.20000196 0,121.20000196 0,122.400002 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="Gt7QuQ4BNnpTM3tk"
                        d="M204.1000061,93.30000305 L204.1000061,142.00000381 C205.30000615,142.00000381 206.30000615,141.00000381 206.30000615,139.80000377 L206.30000615,95.50000453 C206.30000615,94.30000448 205.30000615,93.30000448 204.1000061,93.30000448 Z"
                        style={{ fill: "#ebceb5", opacity: 1.0 }}
                      />
                      <path
                        id="hPuclasitIuFFt7k"
                        d="M204.1000061,93.30000305 L204.1000061,29.90000153 C204.1000061,27.80000162 203.9000061,25.80000162 203.50000608,23.90000153 C203.30000608,22.90000153 203.10000607,22.00000155 202.80000609,21.00000143 C202.40000609,19.60000145 201.80000609,18.20000148 201.20000607,16.90000153 C200.80000606,16.00000155 200.30000609,15.20000148 199.80000609,14.40000153 C199.30000609,13.60000157 198.80000609,12.8000015 198.20000607,12.00000143 C197.30000609,10.80000138 196.40000612,9.80000138 195.40000612,8.70000148 C194.70000613,8.00000149 194.00000614,7.40000153 193.30000621,6.8000015 C188.1000064,2.50000131 181.50000602,0.00000131 174.30000621,0.00000131 L32.10000926,0.00000131 C24.90000945,0.00000131 18.20000964,2.60000122 13.10000926,6.8000015 C12.40000927,7.40000153 11.70000929,8.10000145 11.00000936,8.70000148 C10.00000936,9.70000148 9.00000936,10.80000138 8.20000941,12.00000143 C7.60000938,12.80000144 7.10000938,13.60000145 6.60000938,14.40000153 C6.10000938,15.2000016 5.60000938,16.10000157 5.20000941,16.90000153 C4.50000942,18.3000015 4.00000936,19.60000157 3.50000936,21.00000143 C3.20000935,22.00000143 3.00000936,22.90000141 2.80000937,23.90000153 C2.40000936,25.8000015 2.20000935,27.90000153 2.20000935,29.90000153 L2.20000935,378.19998932 C2.20000935,394.69998932 15.60000896,408.09998894 32.10000896,408.09998894 L174.20001507,408.09998894 C190.70001507,408.09998894 204.10001469,394.69998932 204.10001469,378.19998932 L204.10001469,93.29999542 Z M202.10001469,93.29999542 L202.10001469,378.29999542 C202.10001469,393.69999504 189.60001469,406.19999504 174.20001507,406.19999504 L32.10000896,406.19999504 C16.70000935,406.19999504 4.20000935,393.69999504 4.20000935,378.29999542 L4.20000935,30.00000763 C4.20000935,14.50000763 16.70000935,2.00000763 32.10000896,2.00000763 L174.20001507,2.00000763 C189.60001469,2.00000763 202.10001469,14.50000763 202.10001469,29.90000725 L202.10001469,93.30000877 Z"
                        style={{ fill: "#fce9d8", opacity: 1.0 }}
                      />
                    </g>
                  </svg>
                </div>
                </div>
              </div>
            </div>
            {/* pc */}
            <div className="row my-5">
              <div className="col-sm-6 offset-sm-3">
                <div id="ZGxmH0tXHt5b4Cjc">
                  <div id="iqdp9Mh4tTpYd8Ve">
                    <div id="wiatWyz4U8OkBpwo">
                      <div className="computer">
                        <svg
                          id="J7oCd7sAeBIu2MyR"
                          viewBox="0 0 628.5 360.5"
                          style={{
                            width: "100%",
                            height: "100%",
                            opacity: 1.0,
                            overflow: "hidden",
                            background: 'url("data:image/png',
                          }}
                        >
                          <g id="Na2wmX8wFBBB487j" style={{ transform: "scale(1, 1)" }}
                          >
                            <path
                              id="fcJDnwrtMSF1g2wP"
                              d="M550.40002441,1.70000005 L78.10003662,1.70000005 C68.30003643,1.70000005 60.30003738,9.70000005 60.30003738,19.49999928 L60.30003738,342.39999318 L568.30003738,342.39999318 L568.30003738,19.49999928 C568.20003738,9.69999909 560.200037,1.70000005 550.40003777,1.70000005 Z M552.60003781,320.09999394 L75.80005002,320.09999394 L75.80005002,21.80000615 L552.60003781,21.80000615 L552.60003781,320.09999394 Z"
                              style={{ fill: "#000000", opacity: 1.0 }}
                            />
                            <path
                              id="yJRk3pllFvc7ikPZ"
                              d="M570,342.3999939 L570,19.19998169 C570,8.59998131 561.39999962,-0.00001907 550.79999924,-0.00001907 L77.59998703,-0.00001907 C67.09998703,-0.00001907 58.49998665,8.59998131 58.49998665,19.19998169 L58.49998665,342.49996948 L-0.00001335,342.49996948 L-0.00001335,350.19996929 C-0.00001335,354.3999691 3.39998674,357.69996929 7.49998665,357.69996929 L620.99998665,357.69996929 C625.19998646,357.69996929 628.49998665,354.2999692 628.49998665,350.19996929 L628.49998665,342.49996948 L569.99998665,342.49996948 Z M355.89998055,342.49996948 L355.89998055,342.59996948 C355.89998055,346.79996929 352.49998045,350.09996948 348.39998055,350.09996948 L280.09997749,350.09996948 C275.89997768,350.09996948 272.59997749,346.69996939 272.59997749,342.59996948 L272.59997749,342.49996948 L60.29997444,342.49996948 L60.29997444,19.59997559 C60.29997444,9.7999754 68.29997444,1.79997635 78.09997368,1.79997635 L550.39996147,1.79997635 C560.19996166,1.79997635 568.19996071,9.79997635 568.19996071,19.59997559 L568.19996071,342.49996948 L355.89995766,342.49996948 Z"
                              style={{ fill: "#e9e9e9", opacity: 1.0 }}
                            />
                            <path
                              id="VgxZ5rgchKj3deFB"
                              d="M280.1000061,350.1000061 L348.40000916,350.1000061 C352.60000896,350.1000061 355.90000916,346.70000601 355.90000916,342.6000061 L355.90000916,342.5000061 L272.50000763,342.5000061 L272.50000763,342.6000061 C272.50000763,346.70000601 275.90000772,350.1000061 280.10000753,350.1000061 Z"
                              style={{ fill: "#cccccc", opacity: 1.0 }}
                            />
                            <path
                              id="mljza3vn9pbLkLL8"
                              d="M12.89999962,357.70001221 C12.89999962,359.30001223 14.19999957,360.50001216 15.69999957,360.50001216 L612.79997516,360.50001216 C614.39997518,360.50001216 615.59997511,359.20001221 615.59997511,357.70001221 L12.8999629,357.70001221 Z"
                              style={{ fill: "#cccccc", opacity: 1.0 }}
                            />
                            <foreignObject id="ClyfYKDYM49KHJAR"
                              x="75.8"
                              y="21.8"
                              width="476.8"
                              height="298.4"
                            >
                              <video className="myVideo"
                                id="dbq3m4HlvonQoN3B"
                                src={vedio11}
                                playsInline
                                preload="none"
                                muted
                                autoPlay
                                controls
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "fill",
                                }}
                              >
                                <svg
                                  className="pauseSvg"
                                  style={{ display: "block" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                >
                                  <rect x={7} y={5} width={3} height={14} rx="1.5" fill="currentColor"/>
                                  <rect x={14} y={5} width={3} height={14} rx="1.5" fill="currentColor"/>
                                </svg>
                                <svg className="playSvg2" width={24}
                                  height={24}
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ display: "block" }}
                                >
                                  <path fillRule="evenodd" clipRule="evenodd" d="M8.248 4.212l11.05 6.574c.694.412.91 1.29.483 1.961-.121.19-.287.35-.483.467l-11.05 6.574c-.694.413-1.602.204-2.03-.467A1.39 1.39 0 0 1 6 18.574V5.426C6 4.638 6.66 4 7.475 4c.273 0 .54.073.773.212z" fill="currentColor"
                                  />
                                </svg>
                              </video>
                            </foreignObject>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* web-design */}
            <div className="row my-5  align-items-center">
              <div className="col-sm-5 text-white" id="section-1">
                <h5 className="text-white fw-normal" id="wd">
                  Website <br /> Design
                </h5>

                <img src={spiral1} alt="spiral1" className="img1" />
                <img src={vedio12} alt="vedio12" className="img2" />
              </div>
              <div className="col-sm-7" id="section-2">
                <div className="section-1">
                  <img src={desk} alt="desk" className="img3" />
                  <img src={mobile} alt="mobile" className="img4" />
                </div>
              </div>
            </div>
            {/* Our partnership */}
            <div className="row my-5">
              <h1 id="partnership" className="fw-normal text-white headingpartnership">
                Our Partnerships
              </h1>
              <div className="row mt-5">
                <div className="col-sm-3 px-5">
                  <div className="pi">
                    <img className="logo" alt="mobile" src={logo1} loading="lazy" />
                  </div>
                </div>
                <div className="col-sm-3 px-5">
                  <div className="pi">
                    <img className="logo" alt="mobile" src={logo2} loading="lazy" />
                  </div>
                </div>
                <div className="col-3 px-5">
                  <div className="pi">
                    <img className="logo1" alt="mobile" src={logo3} loading="lazy" />
                  </div>
                </div>
                <div className="col-sm-3 px-5">
                  <div className="pi">
                    <img className="logo" alt="mobile" src={vedio14} loading="lazy" />
                  </div>
                </div>
       
                <div className="col-sm-4 px-5">
                  <div className="pi">
                    <img className="logo1" alt="mobile" src={vedio15} loading="lazy " />
                  </div>
                </div>
                <div className="col-sm-4 px-5">
                  <div className="pi">
                    <img className="logo1" alt="mobile" src={vedio16} loading="lazy" />
                  </div>
                </div>
                <div className="col-sm-4 px-5">
                  <div className="pi">
                    <img className="logo1" alt="mobile" src={vedio13} loading="lazy" />
                  </div>
                </div>
              </div>
            </div>
            {/* Get in touch */}
            <div id="contact" className="row my-5">
              <div className="col-sm-12">
                <h6 className="getstarted-heading text-white" id="Ltgs">
                  Looking To Get Started?
                </h6>
                <div className="getstarted-container my-5 text-center">
                  <p className="paragraph  text-white">
                    <i>Fill out the form below and we’ll be in touch...</i>
                    <img className="arrow" src={arrow} alt="arrow" />
                  </p>
                  <button
                    className="get-in-touch my-5"
                    
                    type="button"
                    onClick={openModal}
                  >

                    Get In Touch!

                  </button>

                </div>
                

                <Modal className="modal-lg" show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>What are you looking for?</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ConnectForm />
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Connect;