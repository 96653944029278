
import React, { useState } from "react";
import axios from "axios";


const ConnectForm = () => {
  const [formData, setFormData] = useState({
    interests: [],
    name: "",
    email: "",
    phone: "",
    company: "",
    budget: "",
    goals: "",
  });

  const [formStatus, setFormStatus] = useState("Submit"); // Initial form status

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      const updatedInterests = checked
        ? [...formData.interests, value]
        : formData.interests.filter((item) => item !== value);
      setFormData({ ...formData, interests: updatedInterests });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus("Sending..."); // Update form status when sending

    try {
      const response = await axios.post("https://stoyc.com/mail.php", formData);
      console.log(response.data); // Handle success response
      setFormStatus("Sent"); // Update form status after successful submission
    } catch (error) {
      console.error("Error:", error); // Handle error response
      setFormStatus("Error"); // Update form status in case of error
    }
  };
  return (
    <section className="contact-form " id="inquiry">
      <div className="container ">
        <form
          id="contact-form"
          className="contact__form "
          onSubmit={handleSubmit}
        >
         

          <div className="up">Choose as many as you like</div>
          <ul className="services connect list-unstyled d-flex flex-wrap">
            <li className="form-group">
              <input
                onChange={handleChange}
                id="Social"
                name="interests[]"
                type="checkbox"
                value="Social Media	Management   &amp; Optimization"
              />
              <label htmlFor="Social">Content Creation</label>
            </li>
            <li className="form-group">
              <input
                onChange={handleChange}
                id="digital"
                name="interests[]"
                type="checkbox"
                value="Digital Marketing"
              />
              <label htmlFor="digital"> Social Media Management</label>
            </li>

            <li className="form-group">
              <input
                onChange={handleChange}
                id="design"
                name="interests[]"
                type="checkbox"
                value="Website Design and Development"
              />
              <label htmlFor="design">Facebook & Google Advertising</label>
            </li>
            <li className="form-group">
              <input
                onChange={handleChange}
                id="branding"
                name="interests[]"
                type="checkbox"
                value="Branding and Identity"
              />
              <label htmlFor="branding">Website design with IDX</label>
            </li>
            <li className="form-group">
              <input
                onChange={handleChange}
                id="creation"
                name="interests[]"
                type="checkbox"
                value="Content Creation"
              />
              <label htmlFor="creation">All of the Above</label>
            </li>
            <li className="form-group">
              <input
                onChange={handleChange}
                id="strategy"
                name="interests[]"
                type="checkbox"
                value="Brand Strategy htmlFor Start-Ups"
              />
              <label htmlFor="strategy">Not Sure Yet</label>
            </li>
            <li className="form-group">
              <input
                onChange={handleChange}
                id="SEO"
                name="interests[]"
                type="checkbox"
                value="Search Engine Optimization (SEO)"
              />
            </li>
          </ul>
          <div className="up">We'll Be In Touch!</div>
          <ul className="info list-unstyled ">
            <li className="large form-group">
              <input
                onChange={handleChange}
                id="name"
                name="name"
                type="text"
                required
                placeholder="Name"
              />

              {/* <label htmlFor="name">Name</label> */}
            </li>
            <li className="small form-group">
              <input
                onChange={handleChange}
                id="email"
                name="email"
                type="text"
                required
                placeholder="Email"
              />

              {/* <label htmlFor="email">Email</label> */}
            </li>
            <li className="small form-group">
              <input
                onChange={handleChange}
                id="phone"
                name="phone"
                type="text"
                required
                placeholder="Phone"
              />

              {/* <label htmlFor="phone">Phone</label> */}
            </li>
          </ul>
          <button
            className="btn btn-main mb-5"
            type="submit"
            disabled={formStatus === "Sending..." || formStatus === "Sent"}
          >
            {formStatus}
          </button>
        </form>
      </div>
    </section>
  );
};
export default ConnectForm;
