// components
import Header from "../components/Header";
import Footer from "../components/Footer"; 
import React, { useState, useEffect } from 'react';
import Loading from '../components/Loading';
import { useLocation } from 'react-router-dom';
const Layout = ({ children }) => {
    const location = useLocation();
    const showHeader = location.pathname !== '/connect';

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        // Simulate an API call
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    return (
        <>
         
            {showHeader && <Header />}
            <main>{children}</main>
            {showHeader && <Footer />}
          
        </>
    );
};

export default Layout;




