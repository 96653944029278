import Title from '../components/Title'
import Meta from '../components/Meta'
import { Container } from 'react-bootstrap'

const Prices = () => {
    // page content
    const pageTitle = 'where are the prices?'
    const pageDescription = 'Stoyc - where are the prices'
    const keywords = 'digital marketing'
    return (
        <>
            <Meta title={pageTitle} description={pageDescription} keywords={keywords} />
            <Title head={pageTitle} description={pageDescription} />
            <section className="service-extra section mb-5">
                <Container>

                    <p>At STOYC, we are committed to providing customized solutions tailored to each client's
                        unique requirements. This personalized approach extends to our pricing structure,
                        which is why you won't find standardized prices listed on our website. We recognize that
                        every business has distinct goals, challenges, and market conditions. Consequently, we
                        engage closely with our clients to understand their specific needs and objectives. This
                        enables us to craft a customized strategy and pricing plan that aligns with their vision
                        and budget. Our flexible, client-centric approach ensures we deliver the most value and
                        impactful results, avoiding a one-size-fits-all solution.</p>

                </Container>
            </section>
        </>
    )
}

export default Prices