
import { Routes, Route } from "react-router-dom";
import React from "react";
//import AnimatedCursor from "react-animated-cursor"


// Layout
import Layout from "./layout/Layout";

// pages
import Home from "./pages/Home";
import About from "./pages/About";
import Prices from "./pages/Prices";
import Services from "./pages/Service";
import Projects from "./pages/Project";
import Contact from "./pages/Contact";
import Connect from "./pages/Connect"
import NotFound from "./pages/NotFound";

const App = () => {
  return (
    <Layout>
   
      
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        <Route path="/prices" element={<Prices />} />
        <Route path="/connect" element={ <Connect/>} />
          <Route path='*' element={<NotFound />} />
        </Routes>
     
    </Layout>
  );
};

export default App;
